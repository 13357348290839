import { pageData } from './pageData.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { SectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Segment, Table } from '@/webapp/src/lib/uninty.components.js'

export const MerchantMerchantMemberListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
})(() => {
  return (
    <Block fcnw>
      <Segment
        title="Сотрудники мерчанта"
        size="m"
        // desc={
        //   <Buttons>
        //     <Button as="a" href={adminMerchantMemberNewRoute.get({ merchantSerialNumber: merchant.serialNumber })}>
        //       Добавить сотруника мерчанта
        //     </Button>
        //   </Buttons>
        // }
      >
        <SectionWrapper
          useQuery={() => trpc.getMerchantMembersForMerchantMember.useQuery({})}
          setProps={({ queryResult }) => ({
            merchantMembers: queryResult.data.merchantMembers,
          })}
        >
          {({ merchantMembers }) => {
            return (
              <Table
                records={merchantMembers}
                // href={(record) =>
                //   adminMerchantMemberEditRoute.get({
                //     merchantMemberSerialNumber: record.serialNumber,
                //     merchantSerialNumber: merchant.serialNumber,
                //   })
                // }
                columns={[
                  { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
                  { heading: 'Имя', body: (record) => record.name, width: 200 },
                  { heading: 'Почта', body: (record) => record.email, width: 400 },
                  { heading: 'Телефон', body: (record) => record.phone, width: 200 },
                ]}
              />
            )
          }}
        </SectionWrapper>
      </Segment>
    </Block>
  )
})
