import {
  zEmailRequired,
  zPhoneOptionalNullable,
  zStringOptional,
  zStringRequired,
} from '@/general/src/other/validation.js'
import { z } from 'zod'

export const zCreateMerchantMemberForAdminEndpointInput = z.object({
  merchantId: zStringRequired,
  email: zEmailRequired,
  phone: zPhoneOptionalNullable,
  telegram: zStringOptional,
  name: zStringRequired,
})
