import { ChatsTable } from '@/general/src/chat/components/Chats/index.js'
import { trpc } from '@/webapp/src/lib/trpc.js'

export const UnresolvedChats = ({ viewerType }: { viewerType: 'trader' | 'merchantMember' | 'admin' }) => {
  const query =
    viewerType === 'trader'
      ? trpc.getUnresolvedChatsForTrader
      : viewerType === 'merchantMember'
        ? trpc.getUnresolvedChatsForMerchantMember
        : viewerType === 'admin'
          ? trpc.getUnresolvedChatsForAdmin
          : trpc.getUnresolvedChatsForAdmin
  const queryResult = query.useQuery(
    {},
    {
      refetchInterval: 3_000,
    }
  )
  const chats = queryResult.data?.chats || []
  return (
    <>
      {queryResult.isLoading ? (
        <p>Загрузка...</p>
      ) : !chats.length ? (
        <p>Здесь пусто</p>
      ) : (
        <ChatsTable viewerType={viewerType} chats={chats} />
      )}
    </>
  )
}
