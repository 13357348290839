export const actionLogActions = {
  createTraderDepositForTrader: 'Трейдер создал трейдерский депозит',
  updateMyPasswordForAdmin: 'Админ обновил свой пароль',
  createCustomerDepositForMerchantApi: 'API мерчанта создало клинетский депозит',
  createCustomerWithdrawForMerchantApi: 'API мерчанта создало клинетский виздрав',
  declareCustomerDepositForMerchantCustomer: 'Клиент мерчанта декларировал перевод',
  disputeCancelCustomerDepositForAdmin: 'Админ отменил диспут в клиентском депозите',
  disputeCompleteCustomerDepositForAdmin: 'Админ исполнил диспут в клиентском депозите',
  disputeStartCustomerDepositForTrader: 'Трейдер начал диспут в клиентском депозите',
  pickCustomerDepositForTrader: 'Трейдер взялся за клиентский депозит',
  verifyCustomerDepositForTrader: 'Трейдер подтвердил клиентский депозит',
  cancelCustomerDepositBecauseNotPicked: 'Отмена клиентского депозита, так как его не взяли вовремя',
  cancelCustomerDepositBecauseNotDeclared: 'Отмена клиентского депозита, так как его не декларировали вовремя',
  cancelProcessingCustomerWithdrawForTrader: 'Трейдер отменил обработку клиентского виздрава',
  verifyCustomerWithdrawForTrader: 'Трейдер подтвердил клиентский виздрав',
  disputeCancelCustomerWithdrawForAdmin: 'Админ отменил диспут в клиентском виздраве',
  disputeCompleteCustomerWithdrawForAdmin: 'Админ исполнил диспут в клиентском виздраве',
  disputeStartCustomerWithdrawForMerchantCustomer: 'Клиент мерчанта начал диспут в клиентском виздраве',
  pickCustomerWithdrawForTrader: 'Трейдер взялся за клиентский виздрав',
  cancelCustomerWithdrawBecauseNotPicked: 'Отмена клиентского виздрава, так как его не взяли вовремя',
  createMerchantForAdmin: 'Админ создал мерчанта',
  updateMerchantForAdmin: 'Админ обновил мерчанта',
  updateMyPasswordForMerchantMember: 'Сотрудник мерчанта обновил свой пароль',
  cancelPickingMerchantDepositForMerchantMember:
    'Сотрудник мерчанта отменил мерчантский депозит, до того как его взяли',
  cancelProcessingMerchantDepositForMerchantMember: 'Сотрудник мерчанта отменил обработку мерчантского депозита',
  createMerchantDepositForMerchantMember: 'Сотрудник мерчанта создал мерчантский депозит',
  declareMerchantDepositForMerchantMember: 'Сотрудник мерчанта декларировал мерчантский депозит',
  disputeMakeMerchantDepositForAdmin: 'Админ создал диспут в мерчантском депозите',
  pickMerchantDepositForAdmin: 'Админ взялся за мерчантский депозит',
  verifyMerchantDepositForAdmin: 'Админ подтвердил мерчантский депозит',
  createMerchantMemberForAdmin: 'Админ создал сотрудника мерчанта',
  updateMerchantMemberForAdmin: 'Админ обновил сотрудника мерчанта',
  cancelPickingMerchantWithdrawForMerchantMember:
    'Сотрудник мерчанта отменил мерчантский виздрав, до того как его взяли',
  cancelProcessingMerchantWithdrawForAdmin: 'Админ отменил обработку мерчантского виздрава',
  completeMerchantWithdrawForAdmin: 'Админ исполнил мерчантский виздрав',
  createMerchantWithdrawForMerchantMember: 'Сотрудник мерчанта создал мерчантский виздрав',
  disputeCancelMerchantWithdrawForAdmin: 'Админ отменил диспут в мерчантском виздраве',
  disputeCompleteMerchantWithdrawForAdmin: 'Админ исполнил диспут в мерчантском виздраве',
  disputeStartMerchantWithdrawForMerchantMember: 'Сотрудник мерчанта начал диспут в мерчантском виздраве',
  pickMerchantWithdrawForAdmin: 'Админ взялся за мерчантский виздрав',
  updateCompanyPaymentMethodsForAdmin: 'Админ обновил платежные методы компании',
  updateMerchantPaymentMethodsForMerchantMember: 'Сотрудник мерчанта обновил платежные методы мерчанта',
  updateTraderPaymentMethodsForTrader: 'Трейдер обновил свои платежные методы',
  createTraderForAdmin: 'Админ создал трейдера',
  updateMyPasswordForTrader: 'Трейдер обновил свой пароль',
  updateTraderForAdmin: 'Админ обновил трейдера',
  cancelPickingTraderDepositForTrader: 'Трейдер отменил трейдерский депозит, до того как его взяли',
  cancelProcessingTraderDepositForTrader: 'Трейдер отменил обработку трейдерского депозита',
  declareTraderDepositForTrader: 'Трейдер декларировал трейдерский депозит',
  disputeMakeTraderDepositForAdmin: 'Админ создал диспут в трейдерском депозите',
  pickTraderDepositForAdmin: 'Админ взялся за трейдерский депозит',
  verifyTraderDepositForAdmin: 'Админ подтвердил трейдерский депозит',
  createTraderInsuranceDepositForTrader: 'Трейдер создал страховой депозит',
  cancelPickingTraderInsuranceWithdrawForTrader: 'Трейдер отменил страховой виздрав, до того как его взяли',
  completeTraderInsuranceWithdrawForAdmin: 'Админ исполнил страховой виздрав',
  createTraderInsuranceWithdrawForTrader: 'Трейдер создал страховой виздрав',
  disputeMakeTraderInsuranceWithdrawForAdmin: 'Админ создал диспут в страховом виздраве',
  pickTraderInsuranceWithdrawForAdmin: 'Админ взялся за страховой виздрав',
  updateWebhookConfigForMerchantMember: 'Сотрудник мерчанта обновил конфиг вебхука',
  startOnlineForTrader: 'Трейдер начал быть онлайн',
  stopOnlineForTrader: 'Трейдер прекратил быть онлайн',
  pickCustomerDepositWhileCreating: 'Система назначила клиентский депозит во время его создания на трейдера',
  updateCompanyAvailabilityForAdmin: 'Админ обновил доступность компании',
  createAdminForAdmin: 'Админ создал админа',
  updateAdminForAdmin: 'Админ обновил админа',
  verifyCustomerDepositForTraderAutomator: 'Автоматизатор трейдера подтвердил клиентский депозит',
}
export type ActionLogAction = keyof typeof actionLogActions
export const toHumanActionLogAction = (action: string): string => {
  return (actionLogActions as any)[action] || action
}
