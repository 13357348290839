type WithSerialNumberOrNull = { serialNumber: number } | null
export const getTextPreview = (
  {
    customerWithdraw,
    customerDeposit,
    merchantWithdraw,
    merchantDeposit,
    traderWithdraw,
    traderDeposit,
    traderInsuranceWithdraw,
    traderInsuranceDeposit,
    trader,
    admin,
    merchant,
    merchantMember,
  }: {
    customerWithdraw?: WithSerialNumberOrNull
    customerDeposit?: WithSerialNumberOrNull
    merchantWithdraw?: WithSerialNumberOrNull
    merchantDeposit?: WithSerialNumberOrNull
    traderWithdraw?: WithSerialNumberOrNull
    traderDeposit?: WithSerialNumberOrNull
    traderInsuranceWithdraw?: WithSerialNumberOrNull
    traderInsuranceDeposit?: WithSerialNumberOrNull
    trader?: WithSerialNumberOrNull
    merchant?: WithSerialNumberOrNull
    admin?: WithSerialNumberOrNull
    merchantMember?: WithSerialNumberOrNull
  },
  withPlaceholder?: boolean
) => {
  if (customerWithdraw) {
    return `Клиентский виздрав #${customerWithdraw.serialNumber}`
  }
  if (customerDeposit) {
    return `Клиентский депозит #${customerDeposit.serialNumber}`
  }
  if (merchantWithdraw) {
    return `Мерчантский виздрав #${merchantWithdraw.serialNumber}`
  }
  if (merchantDeposit) {
    return `Мерчантский депозит #${merchantDeposit.serialNumber}`
  }
  if (traderWithdraw) {
    return `Трейдерский виздрав #${traderWithdraw.serialNumber}`
  }
  if (traderDeposit) {
    return `Трейдерский депозит #${traderDeposit.serialNumber}`
  }
  if (traderInsuranceWithdraw) {
    return `Трейдерский страховой виздрав #${traderInsuranceWithdraw.serialNumber}`
  }
  if (traderInsuranceDeposit) {
    return `Трейдерский страховой депозит #${traderInsuranceDeposit.serialNumber}`
  }
  if (trader) {
    return `Трейдер #${trader.serialNumber}`
  }
  if (admin) {
    return `Админ #${admin.serialNumber}`
  }
  if (merchant) {
    return `Мерчант #${merchant.serialNumber}`
  }
  if (merchantMember) {
    return `Сотрудник мерчанта #${merchantMember.serialNumber}`
  }
  return withPlaceholder ? '?' : null
}
