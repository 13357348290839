import { pageData } from './pageData.js'
import { integerWithDecimalsToAmountString } from '@/general/src/other/money.js'
import { zUpdateTraderForAdminEndpointInput } from '@/general/src/trader/routes/updateTraderForAdmin/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Switchy, Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderEditRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'

export const AdminTraderEditPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminTraderEditRoute)
    return trpc.getTraderForAdmin.useQuery({
      traderSerialNumber: +routeParams.traderSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    trader: queryResult.data.trader,
  }),
})(({ trader }) => {
  const updateTraderForAdmin = trpc.updateTraderForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderId: trader.id,
      customerDepositInterestShare: integerWithDecimalsToAmountString({ amount: trader.customerDepositInterestShare }),
      customerWithdrawInterestShare: integerWithDecimalsToAmountString({
        amount: trader.customerWithdrawInterestShare,
      }),
      teamleadCustomerDepositBonusShare: integerWithDecimalsToAmountString({
        amount: trader.teamleadCustomerDepositBonusShare,
      }),
      teamleadCustomerWithdrawBonusShare: integerWithDecimalsToAmountString({
        amount: trader.teamleadCustomerWithdrawBonusShare,
      }),
      name: trader.name,
      phone: trader.phone,
      email: trader.email,
      telegram: trader.telegram,
      referrerSerialNumber: (trader.referrer?.serialNumber.toString() || '') as never as number,
      banned: !!trader.bannedAt,
    },
    validationSchema: zUpdateTraderForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await updateTraderForAdmin.mutateAsync({
        ...valuesInput,
      })
      trpcUtils.getTraderForAdmin.setData({ traderSerialNumber: res.trader.serialNumber }, res)
    },
    successMessage: 'Трейдер обновлён',
  })
  return (
    <Block fcnw>
      <Segment title={`Трейдер #${trader.serialNumber}`} size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Почта" {...formy.getFieldProps('email')} />
          <Textfieldy label="Телефон" {...formy.getFieldProps('phone')} />
          <Textfieldy label="Телеграм" {...formy.getFieldProps('telegram')} />
          <Textfieldy label="Серийный номер пригласившего трейдера" {...formy.getFieldProps('referrerSerialNumber')} />
          <Textfieldy
            label="Процент от клиентского депозита"
            {...formy.getFieldProps('customerDepositInterestShare')}
          />
          <Textfieldy
            label="Процент от клиентского виздрава"
            {...formy.getFieldProps('customerWithdrawInterestShare')}
          />
          <Textfieldy
            label="Тимлдиский процент от клиентского депозита"
            {...formy.getFieldProps('teamleadCustomerDepositBonusShare')}
          />
          <Textfieldy
            label="Тимлидский процент от клиентского виздрава"
            {...formy.getFieldProps('teamleadCustomerWithdrawBonusShare')}
          />
          <Switchy {...formy.getFieldProps('banned')} label="Забанен" optionLabel="Да" />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Сохранить
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
