import { createMoneyThings } from 'svag-money'
import type { z } from 'zod'

export const { toMoney, zCurrency, zAmountIntegerWithDecimalsLimited, integerWithDecimalsToAmountString } =
  createMoneyThings({
    currencies: ['rub', 'usdt'],
    defaultSymbolPosition: 'after',
    defaultSymbolDelimiter: ' ',
  })
export type Currency = z.output<typeof zCurrency>
