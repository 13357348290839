import { pageData } from './pageData.js'
import { SignInPageComponent } from '@/general/src/auth/components/SignInPageComponent/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'

export const TraderSignInPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
})(() => {
  return <SignInPageComponent viewerRole="trader" />
})
