import { pageData } from './pageData.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'

export const MerchantMerchantApiPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQueries: () => {
    return trpc.useQueries((t) => [t.getMe(), t.getMyMerchant()])
  },
  setProps: ({ getAuthorizedMerchantMemberMe, queriesResults }) => ({
    me: getAuthorizedMerchantMemberMe(),
    merchant: queriesResults[1].data.merchant,
  }),
})(({ merchant }) => {
  return (
    <Block fcnw>
      <Segment title="Настройки API" size="m">
        <Segments>
          <Segment title="Данные доступа" size="s">
            <LabeledValues
              items={[
                ['API ключ', merchant.apiKey],
                ['API секрет', merchant.apiSecret],
              ]}
            />
          </Segment>
        </Segments>
      </Segment>
    </Block>
  )
})
