import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { toMoney } from '@/general/src/other/money.js'
import { zCompleteTraderInsuranceWithdrawForAdminEndpointInput } from '@/general/src/traderInsuranceWithdraw/routes/completeTraderInsuranceWithdrawForAdmin/input.js'
import { zDisputeMakeTraderInsuranceWithdrawForAdminEndpointInput } from '@/general/src/traderInsuranceWithdraw/routes/disputeMakeTraderInsuranceWithdrawForAdmin/input.js'
import {
  toHumanTraderInsuranceWithdrawCancellationReason,
  toHumanTraderInsuranceWithdrawStatus,
} from '@/general/src/traderInsuranceWithdraw/utils.shared.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderInsuranceWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

const Complete = ({
  traderInsuranceWithdraw,
}: {
  traderInsuranceWithdraw: TrpcRouterOutput['getTraderInsuranceWithdrawForAdmin']['traderInsuranceWithdraw']
}) => {
  const verifyTraderInsuranceWithdrawForAdmin = trpc.completeTraderInsuranceWithdrawForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderInsuranceWithdrawId: traderInsuranceWithdraw.id,
    },
    validationSchema: zCompleteTraderInsuranceWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderInsuranceWithdraw } = await verifyTraderInsuranceWithdrawForAdmin.mutateAsync(valuesInput)
      trpcUtils.getTraderInsuranceWithdrawForAdmin.setData(
        { traderInsuranceWithdrawSerialNumber: traderInsuranceWithdraw.serialNumber },
        { traderInsuranceWithdraw }
      )
    },
    successMessage: 'Трейдерский страховой виздрав успешно завершён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Одобрить страховой виздрав
        </Button>
      </Buttons>
    </FormItems>
  )
}

const DisputeMake = ({
  traderInsuranceWithdraw,
}: {
  traderInsuranceWithdraw: TrpcRouterOutput['getTraderInsuranceWithdrawForAdmin']['traderInsuranceWithdraw']
}) => {
  const disputeMakeTraderInsuranceWithdrawForAdmin = trpc.disputeMakeTraderInsuranceWithdrawForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      traderInsuranceWithdrawId: traderInsuranceWithdraw.id,
      message: '',
    },
    validationSchema: zDisputeMakeTraderInsuranceWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderInsuranceWithdraw } = await disputeMakeTraderInsuranceWithdrawForAdmin.mutateAsync(valuesInput)
      trpcUtils.getTraderInsuranceWithdrawForAdmin.setData(
        { traderInsuranceWithdrawSerialNumber: traderInsuranceWithdraw.serialNumber },
        { traderInsuranceWithdraw }
      )
    },
    successMessage: 'Трейдерский страховой виздрав успешно отменён через спор',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина спора" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const AdminTraderInsuranceWithdrawViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminTraderInsuranceWithdrawViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getTraderInsuranceWithdrawForAdmin.useQuery(
      {
        traderInsuranceWithdrawSerialNumber: +routeParams.traderInsuranceWithdrawSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.traderInsuranceWithdraw.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    traderInsuranceWithdraw: queryResult.data.traderInsuranceWithdraw,
  }),
})(({ traderInsuranceWithdraw }) => {
  return (
    <Segment title={`Трейдерский страховой виздрав #${traderInsuranceWithdraw.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanTraderInsuranceWithdrawStatus(traderInsuranceWithdraw.status)],
            ['Сумма в USDT', toMoney({ amount: traderInsuranceWithdraw.amountUsdt, currency: 'usdt' })],
            ['Админ', traderInsuranceWithdraw.admin?.serialNumber && `#${traderInsuranceWithdraw.admin.serialNumber}`],
            ['Трейдер', `#${traderInsuranceWithdraw.trader.serialNumber}`],
            ['Создание', formatDate(traderInsuranceWithdraw.createdAt, 'dd.MM.yyyy HH:mm')],
            [
              'Взят в работу',
              traderInsuranceWithdraw.pickedAt && formatDate(traderInsuranceWithdraw.pickedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Одобрено',
              traderInsuranceWithdraw.completedAt &&
                formatDate(traderInsuranceWithdraw.completedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отменено',
              traderInsuranceWithdraw.cancelledAt &&
                formatDate(traderInsuranceWithdraw.cancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Начало спора',
              traderInsuranceWithdraw.disputeStartedAt &&
                formatDate(traderInsuranceWithdraw.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Завершение спора',
              traderInsuranceWithdraw.disputeCompletedAt &&
                formatDate(traderInsuranceWithdraw.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отмена спора',
              traderInsuranceWithdraw.disputeCancelledAt &&
                formatDate(traderInsuranceWithdraw.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Длительность в минутах',
              traderInsuranceWithdraw.completedAt &&
                differenceInMinutes(traderInsuranceWithdraw.completedAt, traderInsuranceWithdraw.createdAt),
            ],
          ]}
        />
        {traderInsuranceWithdraw.status === 'picking' ? (
          <Segment
            title="Ваша задача"
            size="m"
            desc="Перейти на админский дашборд и взяться за этот трейдерский страховой виздрав"
          />
        ) : traderInsuranceWithdraw.status === 'processing' ? (
          <>
            <Segment
              title="Ваша задача"
              size="m"
              desc="Решить, можно ли ви этому трейдеру вывести его средства со страхового баланса на свободный баланс"
            >
              <Complete traderInsuranceWithdraw={traderInsuranceWithdraw} />
            </Segment>

            <Segment
              title="Отмена через спор"
              size="m"
              desc="Если вы считаете, что этому трейдеру нельзя выводить деньги со страхового баланса, тогда отменяйте сделку"
            >
              <DisputeMake traderInsuranceWithdraw={traderInsuranceWithdraw} />
            </Segment>
          </>
        ) : traderInsuranceWithdraw.status === 'completed' ? (
          <Segment title="Сделка успешно завершена" size="m" />
        ) : traderInsuranceWithdraw.status === 'cancelled' ? (
          <Segment
            title="Сделка была отменена"
            size="m"
            desc={toHumanTraderInsuranceWithdrawCancellationReason(
              traderInsuranceWithdraw.cancellationCode,
              traderInsuranceWithdraw.cancellationMessage
            )}
          />
        ) : null}
        <Chat chatId={traderInsuranceWithdraw.chatId} viewerType="admin" />
        <Segment title="Связанные транзакции" size="m">
          <Txs viewerType="admin" dealId={traderInsuranceWithdraw.id} />
        </Segment>
        <Segment title="Связанные логи" size="m">
          <ActionLogs dealId={traderInsuranceWithdraw.id} />
        </Segment>
      </Block>
    </Segment>
  )
})
