import type {
  CustomerDepositCancellationCode,
  CustomerDepositStatus as PrismaCustomerDepositStatus,
} from '@/backend/src/services/other/prisma.js'
import type { CheckIfArraySatisfies } from 'svag-types'
import { z } from 'zod'

export const customerDepositStatuses = [
  'picking',
  'processing',
  'verifying',
  'completed',
  'cancelled',
  'disputing',
] as const satisfies PrismaCustomerDepositStatus[]
export const zCustomerDepositStatus = z.enum(customerDepositStatuses)
export type CustomerDepositStatus = (typeof customerDepositStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: CheckIfArraySatisfies<CustomerDepositStatus, PrismaCustomerDepositStatus> = true

export const toHumanCustomerDepositStatus = (status: CustomerDepositStatus) => {
  return {
    picking: 'Поиск исполнителя',
    processing: 'Ожидание перевода',
    verifying: 'Проверка перевода',
    completed: 'Завершён',
    cancelled: 'Отменён',
    disputing: 'Спор',
  }[status]
}

export const toHumanCustomerDepositCancellationCode = (code: CustomerDepositCancellationCode | null) => {
  if (!code) {
    return 'Сделка не отменена'
  }
  return {
    picking_system_expires: 'Трейдер-исполнитель не был найден в заданный срок',
    processing_system_expires: 'Перевод не был произведён клиентом в заданный срок',
    disputing_admin_disputeCompletion: 'Спор завершён администратором в пользу платформы',
  }[code]
}

export const toHumanCustomerDepositCancellationReason = (
  code: CustomerDepositCancellationCode | null,
  message: string | null
) => {
  const humanCode = toHumanCustomerDepositCancellationCode(code)
  if (!message) {
    return humanCode
  }
  return `${humanCode}: ${message}`
}
