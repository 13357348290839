import type { ChatMessageAuthorRole as PrismaChatMessageAuthorRole } from '@/backend/src/services/other/prisma.js'
import { zStringOptionalNullable, zStringRequired } from '@/general/src/other/validation.js'
import type { CheckIfArraySatisfies } from 'svag-types'
import { z } from 'zod'

export const chatMessageAuthorRoles = ['admin', 'trader', 'merchantMember', 'merchantCustomer', 'system'] as const
export const zChatMessageAuthorRole = z.enum(chatMessageAuthorRoles)
export type ChatMessageAuthorRole = (typeof chatMessageAuthorRoles)[number]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: CheckIfArraySatisfies<ChatMessageAuthorRole, PrismaChatMessageAuthorRole> = true

export const zGetChatWithoutSecretInput = z.object({
  chatId: zStringRequired,
  dealId: zStringOptionalNullable, // not required, just type fix
  dealSecret: zStringOptionalNullable, // not required, just type fix
})
export type GetChatWithoutSecretInput = z.output<typeof zGetChatWithoutSecretInput>

export const zGetChatWithSecretInput = z.object({
  chatId: zStringRequired,
  dealId: zStringRequired,
  dealSecret: zStringRequired,
})
export type GetChatWithSecretInput = z.output<typeof zGetChatWithSecretInput>

export const toHumanChatMessageAuthorRole = (status: ChatMessageAuthorRole) => {
  return {
    admin: 'Администратор',
    trader: 'Трейдер',
    merchantMember: 'Сотрудник',
    merchantCustomer: 'Клиент',
    system: 'Система',
  }[status]
}

export const toHumanChatMessageAuthor = ({
  admin,
  trader,
  merchantMember,
  authorRole,
}: {
  admin?: { serialNumber: number } | null
  trader?: { serialNumber: number } | null
  merchantMember?: { serialNumber: number } | null
  authorRole: ChatMessageAuthorRole
}) => {
  const humanAuthorRole = toHumanChatMessageAuthorRole(authorRole)
  const serialNumber =
    authorRole === 'admin'
      ? admin?.serialNumber
      : authorRole === 'trader'
        ? trader?.serialNumber
        : authorRole === 'merchantMember'
          ? merchantMember?.serialNumber
          : null
  if (!serialNumber) {
    return humanAuthorRole
  }
  return `${humanAuthorRole} #${serialNumber}`
}
