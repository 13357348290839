import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { zPickCustomerWithdrawForTraderEndpointInput } from '@/general/src/customerWithdraw/routes/pickCustomerWithdrawForTrader/input.js'
import { toHumanCustomerWithdrawStatus } from '@/general/src/customerWithdraw/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import type { ClientPaymentMethodForOwner } from '@/general/src/paymentMethod/utils.server.js'
import {
  filterPaymentMethodsByGroupAndType,
  toHumanPaymentMethodType,
  toHumanReadablePaymentMethod,
} from '@/general/src/paymentMethod/utils.shared.js'
import type { AuthorizedTraderMe } from '@/webapp/src/lib/ctx.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { traderCustomerWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Card, ContextMenuItem, Link, Table, WithPopover } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CustomerWithdrawPickerItem = ({
  customerWithdraw,
  paymentMethod,
  setSubmitting,
}: {
  customerWithdraw: TrpcRouterOutput['getCustomerWithdrawsForTrader']['customerWithdraws'][number]
  paymentMethod: ClientPaymentMethodForOwner
  setSubmitting: (submitting: boolean) => void
}) => {
  const navigate = useNavigate()
  const pickCustomerWithdrawForTrader = trpc.pickCustomerWithdrawForTrader.useMutation()
  const formy = useFormy({
    initialValues: {
      paymentMethodId: paymentMethod.id,
      customerWithdrawId: customerWithdraw.id,
    },
    validationSchema: zPickCustomerWithdrawForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerWithdraw } = await pickCustomerWithdrawForTrader.mutateAsync(valuesInput)
      navigate(traderCustomerWithdrawViewRoute.get({ customerWithdrawSerialNumber: customerWithdraw.serialNumber }))
    },
    successMessage: 'Клиентский виздрав принят',
    resetOnSuccess: false,
  })
  useEffect(() => {
    if (formy.isSubmitting) {
      setSubmitting(true)
    } else {
      setSubmitting(false)
    }
  }, [formy.isSubmitting])
  return (
    <ContextMenuItem
      onClick={() => {
        formy.handleSubmit()
      }}
    >
      {toHumanReadablePaymentMethod(paymentMethod)}
    </ContextMenuItem>
  )
}

const CustomerWithdrawPicker = ({
  customerWithdraw,
  me,
}: {
  customerWithdraw: TrpcRouterOutput['getCustomerWithdrawsForTrader']['customerWithdraws'][number]
  me: AuthorizedTraderMe
}) => {
  const [submitting, setSubmitting] = useState(false)
  const paymentMethods = filterPaymentMethodsByGroupAndType(
    me.trader.paymentMethods,
    customerWithdraw.paymentMethodGroup,
    customerWithdraw.paymentMethodType,
    'output'
  )

  return (
    <WithPopover
      popover={
        <Card>
          {!paymentMethods.length ? (
            <p>У вас нет подоходящего платёжного метода</p>
          ) : (
            <>
              <div style={{ display: submitting ? 'block' : 'none' }}>Загрузка...</div>
              <div style={{ display: submitting ? 'none' : 'block' }}>
                {paymentMethods.map((paymentMethod, i) => (
                  <CustomerWithdrawPickerItem
                    key={i}
                    customerWithdraw={customerWithdraw}
                    paymentMethod={paymentMethod}
                    setSubmitting={setSubmitting}
                  />
                ))}
              </div>
            </>
          )}
        </Card>
      }
    >
      <Link href="#" onClick={(e) => e.preventDefault()}>
        Взяться
      </Link>
    </WithPopover>
  )
}

export const AvailableCustomerWithdraws = withSectionWrapper({
  forwardProps: (props: { me: AuthorizedTraderMe }) => props,
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getCustomerWithdrawsForTrader.useQuery(
      {
        available: true,
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult, me }) => ({
    customerWithdraws: queryResult.data.customerWithdraws,
    me,
  }),
})(({ customerWithdraws, me }) => {
  return !customerWithdraws.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      records={customerWithdraws}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        { heading: 'Статус', body: (record) => toHumanCustomerWithdrawStatus(record.status), width: 150 },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Метод',
          body: (record) => toHumanPaymentMethodType(record.paymentMethodType),
          width: 200,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.dealAmount, currency: record.dealCurrency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
        {
          heading: null,
          body: (record) => <CustomerWithdrawPicker customerWithdraw={record} me={me} />,
          width: 100,
        },
      ]}
    />
  )
})
