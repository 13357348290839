import { zAmountIntegerWithDecimalsLimited } from '@/general/src/other/money.js'
import { zStringRequired } from '@/general/src/other/validation.js'
import { z } from 'zod'

export const zUpdateMerchantForAdminEndpointInput = z.object({
  merchantId: zStringRequired,
  name: zStringRequired,
  customerWithdrawFeeShare: zAmountIntegerWithDecimalsLimited(100, 1_000),
  customerDepositFeeShare: zAmountIntegerWithDecimalsLimited(100, 1_000),
  banned: z.boolean(),
})
