import { zEmailRequired, zPhoneOptionalNullable, zStringRequired } from '@/general/src/other/validation.js'
import { z } from 'zod'

export const zUpdateAdminForAdminEndpointInput = z.object({
  adminId: zStringRequired,
  email: zEmailRequired,
  phone: zPhoneOptionalNullable,
  telegram: zStringRequired,
  name: zStringRequired,
  banned: z.boolean(),
})
