import css from './index.module.scss'
import { ApiDocsCreateCustomerDeposit } from '@/general/src/api/docs/ApiDocsCreateCustomerDeposit.js'
import { ApiDocsCreateCustomerWithdraw } from '@/general/src/api/docs/ApiDocsCreateCustomerWithdraw.js'
import { ApiDocsGetPaymentMethods } from '@/general/src/api/docs/ApiDocsGetPaymentMethods.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

export const ApiDocsPage = withPageWrapper({
  title: 'Документация по API',
  useQuery: () => {
    const { me } = useAppContext()
    return trpc.getMyMerchant.useQuery(undefined, {
      enabled: !!me.merchantMember,
    })
  },
  Layout: GeneralLayout,
  setProps: ({ queryResult }) => {
    return {
      merchant: queryResult.data?.merchant,
    }
  },
})(({ merchant }) => {
  return (
    <Segment title="Документация по API" size="m">
      <Block fcnw g={10}>
        <p>
          Если вы авторизованы в мерчантской, то значения API-ключей и секретов подставятся в форме ниже автоматически.
          При отправке запроса (клик на кнопку "Отправить запрос") – будут отправлены реальные запросы на сервер прямо
          из вашего браузера.
        </p>
        <p>
          В запросах на создание клиенсткого депозита или создание клиентского виздрава – обязательно поле{' '}
          <strong className={css.strong}>idempotencyKey</strong>. Это поле, которое вы должны генерировать и хранить на
          своей стороне.
        </p>
        <ApiDocsGetPaymentMethods merchant={merchant} />
        <ApiDocsCreateCustomerDeposit merchant={merchant} />
        <ApiDocsCreateCustomerWithdraw merchant={merchant} />
      </Block>
    </Segment>
  )
})
