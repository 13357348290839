import { pageData } from './pageData.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'

export const TermsPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
})(() => {
  return (
    <div>
      <h1>TermsPage</h1>
    </div>
  )
})
