import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ActionLogs } from '@/general/src/actionLog/components/ActionLogs/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zDisputeCancelCustomerDepositForAdminEndpointInput } from '@/general/src/customerDeposit/routes/disputeCancelCustomerDepositForAdmin/input.js'
import { zDisputeCompleteCustomerDepositForAdminEndpointInput } from '@/general/src/customerDeposit/routes/disputeCompleteCustomerDepositForAdmin/input.js'
import {
  toHumanCustomerDepositCancellationReason,
  toHumanCustomerDepositStatus,
} from '@/general/src/customerDeposit/utils.shared.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { Txs } from '@/general/src/tx/components/Txs/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

const DisputeDecision = ({
  customerDeposit,
}: {
  customerDeposit: TrpcRouterOutput['getCustomerDepositForAdmin']['customerDeposit']
}) => {
  const trpcUtils = trpc.useUtils()

  const disputeCompleteCustomerDepositForAdmin = trpc.disputeCompleteCustomerDepositForAdmin.useMutation()
  const formyComplete = useFormy({
    initialValues: {
      customerDepositId: customerDeposit.id,
    },
    validationSchema: zDisputeCompleteCustomerDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await disputeCompleteCustomerDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getCustomerDepositForAdmin.setData(
        { customerDepositSerialNumber: customerDeposit.serialNumber },
        { customerDeposit }
      )
    },
    successMessage: 'Спор успешно исполнен',
    resetOnSuccess: false,
  })

  const disputeCancelCustomerDepositForAdmin = trpc.disputeCancelCustomerDepositForAdmin.useMutation()
  const formyCancel = useFormy({
    initialValues: {
      customerDepositId: customerDeposit.id,
    },
    validationSchema: zDisputeCancelCustomerDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await disputeCancelCustomerDepositForAdmin.mutateAsync(valuesInput)
      trpcUtils.getCustomerDepositForAdmin.setData(
        { customerDepositSerialNumber: customerDeposit.serialNumber },
        { customerDeposit }
      )
    },
    successMessage: 'Спор успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems>
      <FormItems as="form" {...formyComplete.formProps}>
        <Buttons>
          <Button {...formyComplete.buttonProps} type="submit">
            Исполнить спор, трейдер прав, клиент не прав, сделка будет отменена
          </Button>
        </Buttons>
      </FormItems>
      <FormItems as="form" {...formyCancel.formProps}>
        <Buttons>
          <Button {...formyCancel.buttonProps} type="submit">
            Отменить спор, трейдер не прав, клиент прав, сделка вернётся в статус проверки
          </Button>
        </Buttons>
      </FormItems>
    </FormItems>
  )
}

export const AdminCustomerDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminCustomerDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerDepositForAdmin.useQuery(
      {
        customerDepositSerialNumber: +routeParams.customerDepositSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerDeposit: queryResult.data.customerDeposit,
  }),
})(({ customerDeposit }) => {
  return (
    <Segment title={`Клиентский депозит #${customerDeposit.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanCustomerDepositStatus(customerDeposit.status)],
            ['Сумма', toMoney({ amount: customerDeposit.dealAmount, currency: customerDeposit.dealCurrency })],
            [
              'Платёжный метод',
              customerDeposit.paymentMethodType && toHumanPaymentMethodType(customerDeposit.paymentMethodType),
            ],
            ['Сумма в USDT', toMoney({ amount: customerDeposit.amountUsdt, currency: 'usdt' })],
            [
              'Прибыль компании в USDT',
              !!customerDeposit.companyProfitUsdt &&
                toMoney({ amount: customerDeposit.companyProfitUsdt, currency: 'usdt' }),
            ],
            [
              'Прибыль компании в %',
              !!customerDeposit.companyProfitShare &&
                integerWithDecimalsToAmountString({ amount: customerDeposit.companyProfitShare }) + '%',
            ],
            ['Мерчант', customerDeposit.merchant && `#${customerDeposit.merchant.serialNumber}`],
            [
              'Комиссия мерчанта в USDT',
              !!customerDeposit.merchantFeeUsdt &&
                toMoney({ amount: customerDeposit.merchantFeeUsdt, currency: 'usdt' }),
            ],
            [
              'Комиссия мерчанта в %',
              !!customerDeposit.merchantFeeShare &&
                integerWithDecimalsToAmountString({ amount: customerDeposit.merchantFeeShare }) + '%',
            ],
            ['Трейдер', customerDeposit.trader && `#${customerDeposit.trader.serialNumber}`],
            [
              'Вознаграждение трейдера в USDT',
              !!customerDeposit.traderInterestUsdt &&
                toMoney({ amount: customerDeposit.traderInterestUsdt, currency: 'usdt' }),
            ],
            [
              'Вознаграждение трейдера в %',
              !!customerDeposit.traderInterestShare &&
                integerWithDecimalsToAmountString({ amount: customerDeposit.traderInterestShare }) + '%',
            ],
            [
              'Изначальное вознаграждение трейдера в USDT',
              customerDeposit.traderInterestUsdt !== customerDeposit.traderInitialInterestUsdt &&
              !!customerDeposit.traderInitialInterestUsdt
                ? toMoney({ amount: customerDeposit.traderInitialInterestUsdt, currency: 'usdt' })
                : null,
              'Мерчант платит мало, поэтому пришлось урезать',
            ],
            [
              'Изначальное вознаграждение трейдера в %',
              customerDeposit.traderInterestUsdt !== customerDeposit.traderInitialInterestUsdt &&
              !!customerDeposit.traderInitialInterestShare
                ? integerWithDecimalsToAmountString({ amount: customerDeposit.traderInitialInterestShare }) + '%'
                : null,
              'Мерчант платит мало, поэтому пришлось урезать',
            ],
            [
              'Тимлид',
              customerDeposit.referrer1 &&
                !!customerDeposit.teamleadBonusUsdt &&
                `#${customerDeposit.referrer1.serialNumber}`,
            ],
            [
              'Вознаграждение тимлида в USDT',
              customerDeposit.teamleadBonusUsdt
                ? toMoney({ amount: customerDeposit.teamleadBonusUsdt, currency: 'usdt' })
                : null,
            ],
            [
              'Вознаграждение тимлида в %',
              customerDeposit.teamleadBonusShare
                ? integerWithDecimalsToAmountString({ amount: customerDeposit.teamleadBonusShare }) + '%'
                : null,
            ],
            [
              'Изначальное вознаграждение тимлида в USDT',
              customerDeposit.teamleadBonusUsdt !== customerDeposit.teamleadInitialBonusUsdt &&
              !!customerDeposit.teamleadInitialBonusUsdt
                ? toMoney({ amount: customerDeposit.teamleadInitialBonusUsdt, currency: 'usdt' })
                : null,
              'Мерчант платит мало, поэтому пришлось урезать',
            ],
            [
              'Изначальное вознаграждение тимлида в %',
              customerDeposit.teamleadBonusUsdt !== customerDeposit.teamleadInitialBonusUsdt &&
              !!customerDeposit.teamleadInitialBonusShare
                ? integerWithDecimalsToAmountString({ amount: customerDeposit.teamleadInitialBonusShare }) + '%'
                : null,
              'Мерчант платит мало, поэтому пришлось урезать',
            ],
            ['Пригласивший 1', customerDeposit.referrer1 && `#${customerDeposit.referrer1.serialNumber}`],
            [
              'Вознаграждение пригласившего 1 в USDT',
              customerDeposit.referrer1BonusUsdt
                ? toMoney({ amount: customerDeposit.referrer1BonusUsdt, currency: 'usdt' })
                : null,
            ],
            [
              'Вознаграждение пригласившего 1 в %',
              customerDeposit.referrer1BonusShare
                ? integerWithDecimalsToAmountString({ amount: customerDeposit.referrer1BonusShare }) + '%'
                : null,
            ],
            ['Пригласивший 2', customerDeposit.referrer2 && `#${customerDeposit.referrer2.serialNumber}`],
            [
              'Вознаграждение пригласившего 2 в USDT',
              customerDeposit.referrer2BonusUsdt
                ? toMoney({ amount: customerDeposit.referrer2BonusUsdt, currency: 'usdt' })
                : null,
            ],
            [
              'Вознаграждение пригласившего 2 в %',
              customerDeposit.referrer2BonusShare
                ? integerWithDecimalsToAmountString({ amount: customerDeposit.referrer2BonusShare }) + '%'
                : null,
            ],
            ['Пригласивший 3', customerDeposit.referrer3 && `#${customerDeposit.referrer3.serialNumber}`],
            [
              'Вознаграждение пригласившего 3 в USDT',
              customerDeposit.referrer3BonusUsdt
                ? toMoney({ amount: customerDeposit.referrer3BonusUsdt, currency: 'usdt' })
                : null,
            ],
            [
              'Вознаграждение пригласившего 3 в %',
              customerDeposit.referrer3BonusShare
                ? integerWithDecimalsToAmountString({ amount: customerDeposit.referrer3BonusShare }) + '%'
                : null,
            ],
            ['Создание', formatDate(customerDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
            ['Взят в работу', customerDeposit.pickedAt && formatDate(customerDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
            ['Оплачено', customerDeposit.declaredAt && formatDate(customerDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
            ['Проверено', customerDeposit.completedAt && formatDate(customerDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
            ['Отменено', customerDeposit.cancelledAt && formatDate(customerDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
            [
              'Начало спора',
              customerDeposit.disputeStartedAt && formatDate(customerDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Завершение спора',
              customerDeposit.disputeCompletedAt && formatDate(customerDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отмена спора',
              customerDeposit.disputeCancelledAt && formatDate(customerDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Длительность в минутах',
              customerDeposit.finishedAt && differenceInMinutes(customerDeposit.finishedAt, customerDeposit.createdAt),
            ],
          ]}
        />
        {customerDeposit.status === 'completed' ? (
          <Segment title="Сделка успешно завершена" size="m" />
        ) : customerDeposit.status === 'cancelled' ? (
          <Segment
            title="Сделка была отменена"
            size="m"
            desc={toHumanCustomerDepositCancellationReason(
              customerDeposit.cancellationCode,
              customerDeposit.cancellationMessage
            )}
          />
        ) : customerDeposit.status === 'disputing' ? (
          <Segment title="Разрешите спор" size="m" desc={customerDeposit.disputeStartMessage}>
            <DisputeDecision customerDeposit={customerDeposit} />
          </Segment>
        ) : null}
        <Chat chatId={customerDeposit.chatId} viewerType="admin" />
        <Segment title="Связанные транзакции" size="m">
          <Txs viewerType="admin" dealId={customerDeposit.id} />
        </Segment>
        <Segment title="Связанные логи" size="m">
          <ActionLogs dealId={customerDeposit.id} />
        </Segment>
      </Block>
    </Segment>
  )
})
