/* eslint-disable regexp/no-obscure-range */
// import { normalizeBankCard, normalizeEmail, normalizePhone, validateBankCardByLuhn, validatePhone } from 'svag-utils'
import { normalizeBankCard, normalizeEmail, normalizePhone, validateBankCardByLuhn } from 'svag-utils'
import { z } from 'zod'

export const zStringRequired = z
  .string({
    message: 'Обязательное поле',
    required_error: 'Обязательное поле',
  })
  .min(1, 'Обязательное поле')
  .max(3_000, 'Слишком длинное значение')
export const zStringOptional = z.string().max(3_000, 'Слишком длинное значение').optional()
export const zStringOptionalNullable = zStringOptional.nullable()
export const zEmptyStringOrNull = z.union([z.literal(''), z.null(), z.undefined()])
export const zPasswordRequired = z
  .string({})
  .min(8, 'Пароль должен содержать минимум 8 символов')
  .max(100, 'Пароль должен содержать максимум 100 символов')
  .regex(
    // should contain at least one digit
    /\d/,
    'Пароль должен сожержать хотябы одну цифру'
  )
  .regex(
    // should contain at least one lowercase letter
    /[a-zа-я]/,
    'Пароль должен сожержать хотябы одну строчную букву'
  )
  .regex(
    // should contain at least one uppercase letter
    /[A-ZА-Я]/,
    'Пароль должен сожержать хотябы одну заглавную букву'
  )
  .regex(
    // should contain at least one special character
    /[^a-zA-Zа-яА-Я\d]/,
    'Пароль должен сожержать хотябы один спецсимвол'
  )
export const zEmailRequired = zStringRequired.email('Некорректный email').transform((val) => normalizeEmail(val))
export const zEmailOptionalNullable = z.union([zEmptyStringOrNull, zEmailRequired]).transform((val) => val || null)
export const zUrlRequired = zStringRequired.url('Некорректный URL')
export const zPhoneRequired = zStringRequired
  .regex(
    // allow numbers, spaces, dashes, brackets, plus sign
    // eslint-disable-next-line regexp/strict
    /^[\d\s-()+]+$/,
    'Некорректный номер телефона'
  )
  .superRefine((val, ctx) => {
    const phone = val.replace(/\D/g, '')
    if (phone.startsWith('7') || phone.startsWith('8')) {
      if (phone.length !== 11) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Некорректный номер телефона',
        })
      }
    } else {
      if (phone.length < 3) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Некорректный номер телефона',
        })
      }
      // if (!validatePhone(phone)) {
      //   ctx.addIssue({
      //     code: z.ZodIssueCode.custom,
      //     message: 'Некорректный номер телефона',
      //   })
      // }
    }
  })
  .transform((val) => normalizePhone(val))
export const zPhoneOptionalNullable = z.union([zEmptyStringOrNull, zPhoneRequired]).transform((val) => val || null)

export const zPasswordsMustBeTheSame =
  (passwordFieldName: string, passwordAgainFieldName: string) => (val: any, ctx: z.RefinementCtx) => {
    if (val[passwordFieldName] !== val[passwordAgainFieldName]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Пароли должны совпадать',
        path: [passwordAgainFieldName],
      })
    }
  }

export const zBankCardNumberRequired = zStringRequired
  .regex(
    // allow numbers and spaces only
    /^[\d\s]+$/,
    'Недопустимые символы в номере карты'
  )
  .superRefine((val, ctx) => {
    if (validateBankCardByLuhn(val) !== true) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Опечатка в номере карты',
      })
    }
  })
  .transform((val) => normalizeBankCard(val))
export const zBankCardNumberOptionalNullable = z
  .union([zEmptyStringOrNull, zBankCardNumberRequired])
  .transform((val) => val || null)
