import { pageData } from './pageData.js'
import { Chats } from '@/general/src/chat/components/Chats/index.js'
import { UnresolvedChats } from '@/general/src/chat/components/UnresolvedChats/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

export const MerchantChatListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
})(() => {
  return (
    <Block fcnw g={60}>
      <Segment title="Непросмотренные чаты" size="m">
        <UnresolvedChats viewerType="merchantMember" />
      </Segment>
      <Segment title="Все чаты" size="m">
        <Chats viewerType="merchantMember" />
      </Segment>
    </Block>
  )
})
