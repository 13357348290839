import type {
  TxDealType,
  TxInitiatorType,
  TxPatricipantBalanceField,
  TxPatricipantType,
  TxReasonType,
  TxSubjectType,
  TxType as PrismaTxType,
} from '@/backend/src/services/other/prisma.js'
import type { CheckIfArraySatisfies } from 'svag-types'
import { z } from 'zod'

export const txTypes = [
  'cw_body_mf_mh_dealStart',
  'cw_fee_mf_mh_dealStart',
  'cw_body_tif_tih_dealPick',
  'cw_body_tih_tif_dealComplete',
  'cw_body_mh_tf_dealComplete',
  'cw_fee_mh_cf_dealComplete',
  'cw_interest_cf_tf_dealComplete',
  'cw_bonus_r1_cf_tf_dealComplete',
  'cw_bonus_r2_cf_tf_dealComplete',
  'cw_bonus_r3_cf_tf_dealComplete',
  'cw_bonus_t_cf_tf_dealComplete',
  'cw_body_mh_mf_dealCancelPicking',
  'cw_fee_mh_mf_dealCancelPicking',
  'cw_body_mh_mf_dealCancelProcessing',
  'cw_fee_mh_mf_dealCancelProcessing',
  'cw_body_tih_tif_dealCancelProcessing',
  'cw_fee_cf_ch_disputeStart',
  'cw_body_tif_tih_disputeStart',
  'cw_body_tf_th_disputeStart',
  'cw_interest_tif_tih_disputeStart',
  'cw_interest_tf_th_disputeStart',
  'cw_bonus_t_tf_th_disputeStart',
  'cw_body_tih_mf_disputeComplete',
  'cw_body_th_tif_disputeComplete',
  'cw_interest_tih_cf_disputeComplete',
  'cw_interest_th_tif_disputeComplete',
  'cw_fee_ch_mf_disputeComplete',
  'cw_bonus_r1_tf_cf_disputeComplete',
  'cw_bonus_r2_tf_cf_disputeComplete',
  'cw_bonus_r3_tf_cf_disputeComplete',
  'cw_bonus_t_th_cf_disputeComplete',
  'cw_fee_ch_cf_disputeCancel',
  'cw_body_tih_tif_disputeCancel',
  'cw_interest_tih_tif_disputeCancel',
  'cw_body_th_tf_disputeCancel',
  'cw_interest_th_tf_disputeCancel',
  'cw_bonus_t_th_tf_disputeCancel',
  'cd_body_tf_th_dealPick',
  'cd_body_th_mf_dealComplete',
  'cd_fee_mf_cf_dealComplete',
  'cd_interest_cf_tf_dealComplete',
  'cd_bonus_r1_cf_tf_dealComplete',
  'cd_bonus_r2_cf_tf_dealComplete',
  'cd_bonus_r3_cf_tf_dealComplete',
  'cd_bonus_t_cf_tf_dealComplete',
  'cd_body_th_tf_dealCancelProcessing',
  'mw_body_mf_mh_dealStart',
  'mw_body_mh_me_dealComplete',
  'mw_body_cb_void_dealComplete',
  'mw_body_mh_mf_dealCancelPicking',
  'mw_body_mh_mf_dealCancelProcessing',
  'mw_body_cf_ch_disputeStart',
  'mw_body_ch_mf_disputeComplete',
  'mw_body_void_cb_disputeComplete',
  'mw_body_ch_cf_disputeCancel',
  'md_body_me_mf_dealComplete',
  'md_body_void_cb_dealComplete',
  'td_body_te_tf_dealComplete',
  'td_body_void_cb_dealComplete',
  'tiw_body_tif_tih_dealStart',
  'tiw_body_tih_tf_dealComplete',
  'tiw_body_tih_tif_dealCancelPicking',
  'tiw_body_tih_tif_dealCancelProcessing',
  'tid_body_tf_tif_dealComplete',
] as const satisfies PrismaTxType[]
export const zTxType = z.enum(txTypes)
export type TxType = (typeof txTypes)[number]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: CheckIfArraySatisfies<TxType, PrismaTxType> = true

export const toHumanTxPaticipant = (participant: TxPatricipantType) => {
  return {
    merchant: 'Мерчант',
    trader: 'Трейдер',
    company: 'Компания',
    referrer: 'Пригласивший',
  }[participant]
}

export const toHumanTxBlanaceField = (balanceField: TxPatricipantBalanceField | null) => {
  if (!balanceField) {
    return 'Внешний счёт'
  }
  return {
    balanceUsdtBusy: 'Занятый баланс',
    balanceUsdtFree: 'Свободный баланс',
    balanceUsdtHolded: 'Замороженный баланс',
    balanceUsdtInsuranceFree: 'Свободный страховой баланс',
    balanceUsdtInsuranceHolded: 'Замороженный страховой баланс',
  }[balanceField]
}

export const toHumanTxReason = (txReason: TxReasonType) => {
  return {
    dealStart: 'Создание сделки',
    dealPick: 'Взятие сделки',
    dealComplete: 'Завершение сделки',
    dealCancelPicking: 'Отмена сделки на этапе подбора',
    dealCancelProcessing: 'Отмена сделки на этапе процессинга',
    disputeStart: 'Начало спора',
    disputeComplete: 'Одобрение спора',
    disputeCancel: 'Отмена спора',
  }[txReason]
}

export const toHumantTxParticipantBalanceField = (
  participant: TxPatricipantType | null,
  balanceField: TxPatricipantBalanceField | null
) => {
  if (!participant || !balanceField) {
    return 'Внешний счёт'
  }
  return `${toHumanTxPaticipant(participant)}: ${toHumanTxBlanaceField(balanceField)}`
}

export const toHumanTxInitiatorType = (type: TxInitiatorType) => {
  return {
    merchantCustomer: 'Клиент мерчанта',
    system: 'Система',
    trader: 'Трейдер',
    merchantMember: 'Сотрудник мерчанта',
    merchantApi: 'API мерчанта',
    admin: 'Админ',
  }[type]
}

export const toHumanTxDealType = (type: TxDealType) => {
  return {
    customerWithdraw: 'Клиентский виздрав',
    customerDeposit: 'Клиентский депозит',
    merchantWithdraw: 'Мерчантский виздрав',
    merchantDeposit: 'Мерчантский депозит',
    traderWithdraw: 'Трейдерский виздрав',
    traderDeposit: 'Трейдерский депозит',
    traderInsuranceWithdraw: 'Трейдерский страховой виздрав',
    traderInsuranceDeposit: 'Трейдерский страховой депозит',
  }[type]
}

export const toHumanTxSubjectType = (type: TxSubjectType) => {
  return {
    body: 'Тело',
    fee: 'Комиссия',
    interest: 'Вознаграждение',
    bonus: 'Бонус',
  }[type]
}

export const toHumanTxType = (type: TxType) => {
  return (
    {
      cw_body_mf_mh_dealStart:
        '(01) Перевод тела в рамках клиентского виздрава в связи с началом сделки из свободного баланса мерчанта в замороженный баланс мерчанта',
      cw_fee_mf_mh_dealStart:
        '(02) Перевод комиссии в рамках клиентского виздрава в связи с началом сделки из свободного баланса мерчанта в замороженный баланс мерчанта',
      cw_body_tif_tih_dealPick:
        '(03) Перевод тела в рамках клиентского виздрава в связи с взятием сделки из свободного страхового баланса трейдера в замороженный страховой баланс трейдера',
      cw_body_tih_tif_dealComplete:
        '(04) Перевод тела в рамках клиентского виздрава в связи с завершением сделки из замороженного страхового баланса трейдера в свободный страховой баланс трейдера',
      cw_body_mh_tf_dealComplete:
        '(05) Перевод тела в рамках клиентского виздрава в связи с завершением сделки из замороженного баланса мерчанта в свободный баланс трейдера',
      cw_fee_mh_cf_dealComplete:
        '(06) Перевод комиссии в рамках клиентского виздрава в связи с завершением сделки из замороженного баланса мерчанта в свободный баланс компании',
      cw_interest_cf_tf_dealComplete:
        '(07) Перевод вознаграждения в рамках клиентского виздрава в связи с завершением сделки из свободного баланса компании в свободный баланс трейдера',
      cw_body_mh_mf_dealCancelPicking:
        '(08) Перевод тела в рамках клиентского виздрава в связи с отменой сделки на этапе подбора из замороженного баланса мерчанта в свободный баланс мерчанта',
      cw_fee_mh_mf_dealCancelPicking:
        '(09) Перевод комиссии в рамках клиентского виздрава в связи с отменой сделки на этапе подбора из замороженного баланса мерчанта в свободный баланс мерчанта',
      cw_body_tih_tif_dealCancelProcessing:
        '(10) Перевод тела в рамках клиентского виздрава в связи с отменой сделки на этапе процессинга из замороженного страхового баланса трейдера в свободный страховой баланс трейдера',
      cw_fee_cf_ch_disputeStart:
        '(11) Перевод комиссии в рамках клиентского виздрава в связи с началом спора из свободного баланса компании в замороженный баланс компании',
      cw_body_tif_tih_disputeStart:
        '(12) Перевод тела в рамках клиентского виздрава в связи с началом спора из свободного страхового баланса трейдера в замороженный страховой баланс трейдера',
      cw_interest_tif_tih_disputeStart:
        '(13) Перевод вознаграждения в рамках клиентского виздрава в связи с началом спора из свободного страхового баланса трейдера в замороженный страховой баланс трейдера',
      cw_body_tih_mf_disputeComplete:
        '(14) Перевод тела в рамках клиентского виздрава в связи с завершением спора из замороженного страхового баланса трейдера в свободный баланс мерчанта',
      cw_interest_tih_cf_disputeComplete:
        '(15) Перевод вознаграждения в рамках клиентского виздрава в связи с завершением спора из замороженного страхового баланса трейдера в свободный баланс компании',
      cw_fee_ch_mf_disputeComplete:
        '(16) Перевод комиссии в рамках клиентского виздрава в связи с завершением спора из замороженного баланса компании в свободный баланс мерчанта',
      cw_fee_ch_cf_disputeCancel:
        '(17) Перевод комиссии в рамках клиентского виздрава в связи с началом спора из замороженного баланса компании в свободный баланс компании',
      cw_body_tih_tif_disputeCancel:
        '(18) Перевод тела в рамках клиентского виздрава в связи с отменой спора из замороженного страхового баланса трейдера в свободный страховой баланс трейдера',
      cw_interest_tih_tif_disputeCancel:
        '(19) Перевод вознаграждения в рамках клиентского виздрава в связи с отменой спора из замороженного страхового баланса трейдера в свободный страховой баланс трейдера',
      cd_body_tf_th_dealPick:
        '(20) Перевод тела в рамках клиентского депозита в связи с взятием сделки из свободного баланса трейдера в замороженный баланс трейдера',
      cd_body_th_mf_dealComplete:
        '(21) Перевод тела в рамках клиентского депозита в связи с завершением сделки из замороженного баланса трейдера в свободный баланс мерчанта',
      cd_fee_mf_cf_dealComplete:
        '(22) Перевод комиссии в рамках клиентского депозита в связи с завершением сделки из свободного баланса мерчанта в свободный баланс трейдера',
      cd_interest_cf_tf_dealComplete:
        '(23) Перевод вознаграждения в рамках клиентского депозита в связи с завершением сделки из свободного баланса компании в свободный баланс трейдера',
      cd_body_th_tf_dealCancelProcessing:
        '(24) Перевод тела в рамках клиентского депозита в связи с отменой сделки на этапе процессинга из замороженного баланса трейдера в свободный баланс трейдера',
      mw_body_mf_mh_dealStart:
        '(25) Перевод тела в рамках мерчантского виздрава в связи с началом сделки из свободного баланса мерчанта в замороженный баланс мерчанта',
      mw_body_mh_me_dealComplete:
        '(26) Перевод тела в рамках мерчантского виздрава в связи с завершением сделки из замороженного баланса мерчанта в внешний баланс мерчанта',
      mw_body_cb_void_dealComplete:
        '(27) Перевод тела в рамках мерчантского виздрава в связи с завершением сделки из занятого баланса компании в пустоту',
      mw_body_mh_mf_dealCancelPicking:
        '(28) Перевод тела в рамках мерчантского виздрава в связи с отменой сделки на этапе подбора из замороженного баланса мерчанта в свободный баланс мерчанта',
      mw_body_cf_ch_disputeStart:
        '(29) Перевод тела в рамках мерчантского виздрава в связи с началом спора из свободного баланса компании в замороженный баланс компании',
      mw_body_ch_mf_disputeComplete:
        '(30) Перевод тела в рамках мерчантского виздрава в связи с завершением спора из замороженного баланса компании в свободный баланс мерчанта',
      mw_body_ch_cf_disputeCancel:
        '(31) Перевод тела в рамках мерчантского виздрава в связи с отменой спора из замороженного баланса компании в свободный баланс компании',
      md_body_me_mf_dealComplete:
        '(32) Перевод тела в рамках мерчантского депозита в связи с завершением сделки из внешнего баланса мерчанта в свободный баланс мерчанта',
      md_body_void_cb_dealComplete:
        '(33) Перевод тела в рамках мерчантского депозита в связи с завершением сделки из пустоты в занятый баланс компании',
      td_body_te_tf_dealComplete:
        '(34) Перевод тела в рамках трейдерского депозита в связи с завершением сделки из внешнего баланса трейдера в свободный баланс трейдера',
      td_body_void_cb_dealComplete:
        '(35) Перевод тела в рамках трейдерского депозита в связи с завершением сделки из пустоты в баланс занятый компании',
      tiw_body_tif_tih_dealStart:
        '(36) Перевод тела в рамках трейдерского страхового виздрава в связи с началом сделки из свободного страхового баланса трейдера в замороженный страховой баланс трейдера',
      tiw_body_tih_tf_dealComplete:
        '(37) Перевод тела в рамках трейдерского страхового виздрава в связи с завершением сделки из замороженного страхового баланса трейдера в свободный баланс трейдера',
      tiw_body_tih_tif_dealCancelPicking:
        '(38) Перевод тела в рамках трейдерского страхового виздрава в связи с отменой сделки на этапе подбора из замороженного страхового баланса трейдера в свободный страховой баланс трейдера',
      tid_body_tf_tif_dealComplete:
        '(39) Перевод тела в рамках трейдерского страхового депозита в связи с завершением сделки из свободного баланса трейдера в свободный страховой баланс трейдера',
      tiw_body_tih_tif_dealCancelProcessing:
        '(40) Перевод тела в рамках трейдерского страхового виздрава в связи с отменой сделки на этапе процессинга из замороженного страхового баланса трейдера в свободный страховой баланс трейдера',
      mw_body_mh_mf_dealCancelProcessing:
        '(41) Перевод тела в рамках мерчантского виздрава в связи с отменой сделки на этапе процессинга из замороженного баланса мерчанта в свободный баланс мерчанта',
      cw_body_mh_mf_dealCancelProcessing:
        '(42) Перевод тела в рамках клиентского виздрава в связи с отменой сделки на этапе процессинга из замороженного баланса мерчанта в свободный баланс мерчанта',
      cw_fee_mh_mf_dealCancelProcessing:
        '(43) Перевод комиссии в рамках клиентского виздрава в связи с отменой сделки на этапе процессинга из замороженного баланса мерчанта в свободный баланс мерчанта',
      mw_body_void_cb_disputeComplete:
        '(44) Перевод тела в рамках мерчантского виздрава в связи с завершением спора из ниоткуда в занятый баланс компании',
      cw_bonus_r1_cf_tf_dealComplete:
        '(45) Перевод бонуса пригласившего 1 уровня в рамках клиентского виздрава в связи с завершением сделки из свободного баланса компании в свободный баланс пригласившего',
      cw_bonus_r2_cf_tf_dealComplete:
        '(46) Перевод бонуса пригласившего 2 уровня в рамках клиентского виздрава в связи с завершением сделки из свободного баланса компании в свободный баланс пригласившего',
      cw_bonus_r3_cf_tf_dealComplete:
        '(47) Перевод бонуса пригласившего 3 уровня в рамках клиентского виздрава в связи с завершением сделки из свободного баланса компании в свободный баланс пригласившего',
      cw_bonus_t_cf_tf_dealComplete:
        '(48) Перевод бонуса тимлида в рамках клиентского виздрава в связи с завершением сделки из свободного баланса компании в свободный баланс тимлида',
      cw_bonus_r1_tf_cf_disputeComplete:
        '(49) Перевод бонуса пригласившего 1 уровня в рамках клиентского виздрава в связи с завершением спора из свободного баланса пригласившего в свободный баланс компании',
      cw_bonus_r2_tf_cf_disputeComplete:
        '(50) Перевод бонуса пригласившего 2 уровня в рамках клиентского виздрава в связи с завершением спора из свободного баланса пригласившего в свободный баланс компании',
      cw_bonus_r3_tf_cf_disputeComplete:
        '(51) Перевод бонуса пригласившего 3 уровня в рамках клиентского виздрава в связи с завершением спора из свободного баланса пригласившего в свободный баланс компании',
      cw_bonus_t_th_cf_disputeComplete:
        '(52) Перевод бонуса тимлида в рамках клиентского виздрава в связи с завершением спора из замороженного баланса тимлида в свободный баланс компании',
      cd_bonus_r1_cf_tf_dealComplete:
        '(53) Перевод бонуса пригласившего 1 уровня в рамках клиентского депозита в связи с завершением сделки из свободного баланса компании в свободный баланс пригласившего',
      cd_bonus_r2_cf_tf_dealComplete:
        '(54) Перевод бонуса пригласившего 2 уровня в рамках клиентского депозита в связи с завершением сделки из свободного баланса компании в свободный баланс пригласившего',
      cd_bonus_r3_cf_tf_dealComplete:
        '(55) Перевод бонуса пригласившего 3 уровня в рамках клиентского депозита в связи с завершением сделки из свободного баланса компании в свободный баланс пригласившего',
      cd_bonus_t_cf_tf_dealComplete:
        '(56) Перевод бонуса тимлида в рамках клиентского депозита в связи с завершением сделки из свободного баланса компании в свободный баланс тимлида',
      cw_body_tf_th_disputeStart:
        '(57) Перевод тела в рамках клиентского виздрава в связи с началом спора из свободного баланса трейдера в замороженный баланс трейдера',
      cw_bonus_t_tf_th_disputeStart:
        '(58) Перевод бонуса тимлида в рамках клиентского виздрава в связи с началом спора из свободного баланса тимлида в замороженный баланс тимлида',
      cw_body_th_tif_disputeComplete:
        '(59) Перевод тела в рамках клиентского виздрава в связи с завершением спора из замороженного баланса трейдера в свободный страховой баланс трейдера',
      cw_bonus_t_th_tf_disputeCancel:
        '(60) Перевод бонуса тимлида в рамках клиентского виздрава в связи с отменой спора из замороженного баланса тимлида в свободный баланс тимлида',
      cw_interest_tf_th_disputeStart:
        '(61) Перевод вознаграждения в рамках клиентского виздрава в связи с началом спора из свободного баланса трейдера в замороженный баланс трейдера',
      cw_interest_th_tif_disputeComplete:
        '(62) Перевод вознаграждения в рамках клиентского виздрава в связи с завершением спора из замороженного баланса трейдера в свободный страховой баланс трейдера',
      cw_body_th_tf_disputeCancel:
        '(63) Перевод тела в рамках клиентского виздрава в связи с отменой спора из замороженного баланса трейдера в свободный баланс трейдера',
      cw_interest_th_tf_disputeCancel:
        '(64) Перевод вознаграждения в рамках клиентского виздрава в связи с отменой спора из замороженного баланса трейдера в свободный баланс трейдера',
    } satisfies Record<TxType, string>
  )[type]
}
