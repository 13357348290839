import { pageData } from './pageData.js'
import { zUpdateMerchantMemberForAdminEndpointInput } from '@/general/src/merchantMember/routes/updateMerchantMemberForAdmin/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Switchy, Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminMerchantMemberEditRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'

export const AdminMerchantMemberEditPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminMerchantMemberEditRoute)
    return trpc.getMerchantMemberForAdmin.useQuery({
      merchantMemberSerialNumber: +routeParams.merchantMemberSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    merchantMember: queryResult.data.merchantMember,
    merchant: queryResult.data.merchantMember.merchant,
  }),
})(({ merchantMember, merchant }) => {
  const updateMerchantMemberForAdmin = trpc.updateMerchantMemberForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantMemberId: merchantMember.id,
      name: merchantMember.name,
      phone: merchantMember.phone,
      email: merchantMember.email,
      telegram: merchantMember.telegram || '',
      banned: !!merchantMember.bannedAt,
    },
    validationSchema: zUpdateMerchantMemberForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await updateMerchantMemberForAdmin.mutateAsync({
        ...valuesInput,
      })
      trpcUtils.getMerchantMemberForAdmin.setData({ merchantMemberSerialNumber: res.merchantMember.serialNumber }, res)
    },
    successMessage: 'Сотрудник мерчанта обновлён',
  })
  return (
    <Block fcnw>
      <Segment
        title={`Сотрудник мерчанта #${merchant.serialNumber}: #${merchantMember.serialNumber} "${merchantMember.name}"`}
        size="m"
      >
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Почта" {...formy.getFieldProps('email')} />
          <Textfieldy label="Телефон" {...formy.getFieldProps('phone')} />
          <Textfieldy label="Телеграм" {...formy.getFieldProps('telegram')} />
          <Switchy {...formy.getFieldProps('banned')} label="Забанен" optionLabel="Да" />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Сохранить
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
