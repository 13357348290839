import { toHumanMerchantWithdrawStatus } from '@/general/src/merchantWithdraw/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import type { AuthorizedAdminMe } from '@/webapp/src/lib/ctx.js'
import { adminMerchantWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const DisputingMerchantWithdraws = withSectionWrapper({
  forwardProps: (props: { me: AuthorizedAdminMe }) => props,
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getMerchantWithdrawsForAdmin.useQuery(
      {
        status: 'disputing',
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult, me }) => ({
    merchantWithdraws: queryResult.data.merchantWithdraws,
    me,
  }),
})(({ merchantWithdraws }) => {
  return !merchantWithdraws.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      records={merchantWithdraws}
      href={(record) => adminMerchantWithdrawViewRoute.get({ merchantWithdrawSerialNumber: record.serialNumber })}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        { heading: 'Статус', body: (record) => toHumanMerchantWithdrawStatus(record.status), width: 150 },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Метод',
          body: (record) => toHumanPaymentMethodType(record.paymentMethodType),
          width: 200,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
      ]}
    />
  )
})
