import {
  zEmailRequired,
  zPhoneOptionalNullable,
  zStringOptional,
  zStringRequired,
} from '@/general/src/other/validation.js'
import { z } from 'zod'

export const zUpdateMerchantMemberForAdminEndpointInput = z.object({
  merchantMemberId: zStringRequired,
  email: zEmailRequired,
  phone: zPhoneOptionalNullable,
  name: zStringRequired,
  telegram: zStringOptional,
  banned: z.boolean(),
})
