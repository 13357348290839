import { pageData } from './pageData.js'
import { zUpdateMerchantForAdminEndpointInput } from '@/general/src/merchant/routes/updateMerchantForAdmin/input.js'
import { integerWithDecimalsToAmountString } from '@/general/src/other/money.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Switchy, Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminMerchantEditRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'

export const AdminMerchantEditPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminMerchantEditRoute)
    return trpc.getMerchantForAdmin.useQuery({
      merchantSerialNumber: +routeParams.merchantSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    merchant: queryResult.data.merchant,
  }),
})(({ merchant }) => {
  const updateMerchantForAdmin = trpc.updateMerchantForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantId: merchant.id,
      customerDepositFeeShare: integerWithDecimalsToAmountString({ amount: merchant.customerDepositFeeShare }),
      customerWithdrawFeeShare: integerWithDecimalsToAmountString({ amount: merchant.customerWithdrawFeeShare }),
      name: merchant.name,
      banned: !!merchant.bannedAt,
    },
    validationSchema: zUpdateMerchantForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await updateMerchantForAdmin.mutateAsync({
        ...valuesInput,
      })
      trpcUtils.getMerchantForAdmin.setData({ merchantSerialNumber: res.merchant.serialNumber }, res)
    },
    successMessage: 'Мерчант обновлён',
  })
  return (
    <Block fcnw>
      <Segment title={`Мерчант #${merchant.serialNumber}`} size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Процент от клиентского депозита" {...formy.getFieldProps('customerDepositFeeShare')} />
          <Textfieldy label="Процент от клиентского виздрава" {...formy.getFieldProps('customerWithdrawFeeShare')} />
          <Switchy {...formy.getFieldProps('banned')} label="Забанен" optionLabel="Да" />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Сохранить
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
