import { pageData } from './pageData.js'
import { PaymentMethodsEditor } from '@/general/src/paymentMethod/PaymentMethod.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'
import { CompanyAvailability } from '@/webapp/src/pages/admin/other/AdminCompanySettingsPage/components/CompanyAvailability/index.js'

export const AdminCompanySettingsPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    return trpc.getCompany.useQuery()
  },
  setProps: ({ queryResult }) => ({
    company: queryResult.data.company,
  }),
})(({ company }) => {
  return (
    <Block fcnw>
      <Segment title="Настройки компании" size="m">
        <Segments>
          <PaymentMethodsEditor
            updateMyPaymentMethodsTrpcRouteName="updateCompanyPaymentMethodsForAdmin"
            paymentMethods={company.paymentMethods}
          />
          <CompanyAvailability company={company} />
        </Segments>
      </Segment>
    </Block>
  )
})
