/* eslint-disable simple-import-sort/imports */
// @index('../assets/icons/*.{png,jpg,svg}', (f, _) => `import ${_.camelCase(f.name).replace('_', '')} from '${f.path}${f.ext}?react'`)
import apartment from '../assets/icons/apartment.svg?react'
import archive from '../assets/icons/archive.svg?react'
import arrowDown from '../assets/icons/arrow_down.svg?react'
import arrowLeft from '../assets/icons/arrow_left.svg?react'
import arrowRight from '../assets/icons/arrow_right.svg?react'
import arrowUp from '../assets/icons/arrow_up.svg?react'
import attachClip from '../assets/icons/attach_clip.svg?react'
import briefCaseAgency from '../assets/icons/brief_case_agency.svg?react'
import bungalow from '../assets/icons/bungalow.svg?react'
import chatMessageBubble from '../assets/icons/chat_message_bubble.svg?react'
import checkMessageStatus from '../assets/icons/check_message_status.svg?react'
import chevronDown from '../assets/icons/chevron_down.svg?react'
import chevronLeft from '../assets/icons/chevron_left.svg?react'
import chevronRight from '../assets/icons/chevron_right.svg?react'
import chevronUp from '../assets/icons/chevron_up.svg?react'
import closeTimesCircleSolid from '../assets/icons/close_times_circle_solid.svg?react'
import closeTimes from '../assets/icons/close_times.svg?react'
import cottage from '../assets/icons/cottage.svg?react'
import deleteTrashBin from '../assets/icons/delete_trash_bin.svg?react'
import docFile from '../assets/icons/doc_file.svg?react'
import dotPin from '../assets/icons/dot_pin.svg?react'
import doubleCheckMessageStatus from '../assets/icons/double_check_message_status.svg?react'
import eyeVisibilityOff from '../assets/icons/eye_visibility_off.svg?react'
import eyeVisibility from '../assets/icons/eye_visibility.svg?react'
import facebookCircle from '../assets/icons/facebook_circle.svg?react'
import filtersSlider from '../assets/icons/filters_slider.svg?react'
import garage from '../assets/icons/garage.svg?react'
import heartFavoriteSolid from '../assets/icons/heart_favorite_solid.svg?react'
import heartFavorite from '../assets/icons/heart_favorite.svg?react'
import house from '../assets/icons/house.svg?react'
import icon1 from '../assets/icons/icon-1.svg?react'
import icon from '../assets/icons/icon.svg?react'
import infoCircle from '../assets/icons/info_circle.svg?react'
import instagramCircle from '../assets/icons/instagram_circle.svg?react'
import land from '../assets/icons/land.svg?react'
import linkedinCircle from '../assets/icons/linkedin_circle.svg?react'
import listBullet from '../assets/icons/list_bullet.svg?react'
import list from '../assets/icons/list.svg?react'
import locationPinSolid from '../assets/icons/location_pin_solid.svg?react'
import locationPin from '../assets/icons/location_pin.svg?react'
import lock from '../assets/icons/lock.svg?react'
import logoutExit from '../assets/icons/logout_exit.svg?react'
import mail from '../assets/icons/mail.svg?react'
import menuBurger from '../assets/icons/menu_burger.svg?react'
import moreVert from '../assets/icons/more_vert.svg?react'
import myLocation from '../assets/icons/my_location.svg?react'
import notificationsBell from '../assets/icons/notifications_bell.svg?react'
import parkHome from '../assets/icons/park_home.svg?react'
import pauseCircleSuspend from '../assets/icons/pause_circle_suspend.svg?react'
import phoneCall from '../assets/icons/phone_call.svg?react'
import pinterestCircle from '../assets/icons/pinterest_circle.svg?react'
import placeholder from '../assets/icons/placeholder.svg?react'
import plusAdd from '../assets/icons/plus_add.svg?react'
import room from '../assets/icons/room.svg?react'
import searchMagnifier from '../assets/icons/search_magnifier.svg?react'
import send from '../assets/icons/send.svg?react'
import settingsGear from '../assets/icons/settings_gear.svg?react'
import smallChevronDown from '../assets/icons/small_chevron_down.svg?react'
import smallChevronLeft from '../assets/icons/small_chevron_left.svg?react'
import smallChevronRight from '../assets/icons/small_chevron_right.svg?react'
import smallChevronUp from '../assets/icons/small_chevron_up.svg?react'
import tickCheck from '../assets/icons/tick_check.svg?react'
import triangleDown from '../assets/icons/triangle_down.svg?react'
import triangleLeft from '../assets/icons/triangle_left.svg?react'
import triangleRight from '../assets/icons/triangle_right.svg?react'
import triangleUp from '../assets/icons/triangle_up.svg?react'
import userAccountCircle from '../assets/icons/user_account_circle.svg?react'
import warningExclamation from '../assets/icons/warning_exclamation.svg?react'
import youtubeCircle from '../assets/icons/youtube_circle.svg?react'
// @endindex

export const iconsSources = {
  // @index('../assets/icons/*.{png,jpg,svg}', (f, _) => `${_.camelCase(f.name).replace('_', '')},`)
  apartment,
  archive,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  attachClip,
  briefCaseAgency,
  bungalow,
  chatMessageBubble,
  checkMessageStatus,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  closeTimesCircleSolid,
  closeTimes,
  cottage,
  deleteTrashBin,
  docFile,
  dotPin,
  doubleCheckMessageStatus,
  eyeVisibilityOff,
  eyeVisibility,
  facebookCircle,
  filtersSlider,
  garage,
  heartFavoriteSolid,
  heartFavorite,
  house,
  icon1,
  icon,
  infoCircle,
  instagramCircle,
  land,
  linkedinCircle,
  listBullet,
  list,
  locationPinSolid,
  locationPin,
  lock,
  logoutExit,
  mail,
  menuBurger,
  moreVert,
  myLocation,
  notificationsBell,
  parkHome,
  pauseCircleSuspend,
  phoneCall,
  pinterestCircle,
  placeholder,
  plusAdd,
  room,
  searchMagnifier,
  send,
  settingsGear,
  smallChevronDown,
  smallChevronLeft,
  smallChevronRight,
  smallChevronUp,
  tickCheck,
  triangleDown,
  triangleLeft,
  triangleRight,
  triangleUp,
  userAccountCircle,
  warningExclamation,
  youtubeCircle,
  // @endindex
}
