import css from './index.module.scss'
import { useUnresolvedChatsCounterActiualizer } from '@/general/src/chat/components/UnresolvedChatsCounter/index.js'
import { zCreateChatMessageForAdminEndpointInput } from '@/general/src/chat/routes/admin/createChatMessageForAdmin/input.js'
import { zResolveChatForAdminEndpointInput } from '@/general/src/chat/routes/admin/resolveChatForAdmin/input.js'
import { zCreateChatMessageForMerchantCustomerEndpointInput } from '@/general/src/chat/routes/merchantCustomer/createChatMessageForMerchantCustomer/input.js'
import { zResolveChatForMerchantCustomerEndpointInput } from '@/general/src/chat/routes/merchantCustomer/resolveChatForMerchantCustomer/input.js'
import { zCreateChatMessageForMerchantMemberEndpointInput } from '@/general/src/chat/routes/merchantMember/createChatMessageForMerchantMember/input.js'
import { zResolveChatForMerchantMemberEndpointInput } from '@/general/src/chat/routes/merchantMember/resolveChatForMerchantMember/input.js'
import { zCreateChatMessageForTraderEndpointInput } from '@/general/src/chat/routes/trader/createChatMessageForTrader/input.js'
import { zResolveChatForTraderEndpointInput } from '@/general/src/chat/routes/trader/resolveChatForTrader/input.js'
import type { ClientChatMessage } from '@/general/src/chat/utils.server.js'
import { toHumanChatMessageAuthor } from '@/general/src/chat/utils.shared.js'
import { getS3UploadName } from '@/general/src/upload/utils.shared.js'
import { useAppContext } from '@/webapp/src/lib/ctx.js'
import { Textary, useFormy } from '@/webapp/src/lib/formy.js'
import { UploadsToS3 } from '@/webapp/src/lib/formy/UploadsToS3.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { beep } from '@/webapp/src/lib/utils.js'
import { formatDate } from 'date-fns/format'
import { useCallback, useEffect, useRef, useState } from 'react'

export const Chat = ({
  chatId,
  viewerType,
  dealId,
  dealSecret,
}: { chatId: string } & (
  | {
      // me: AuthorizedAdminMe
      viewerType: 'admin'
      dealId?: null
      dealSecret?: null
    }
  | {
      // me: AuthorizedTraderMe
      viewerType: 'trader'
      dealId?: null
      dealSecret?: null
    }
  | {
      // me: AuthorizedMerchantMemberMe
      viewerType: 'merchantMember'
      dealId?: null
      dealSecret?: null
    }
  | {
      // me?: null
      viewerType: 'merchantCustomer'
      dealId: string
      dealSecret: string
    }
)) => {
  const ctx = useAppContext()
  const [initial, setInitial] = useState(true)
  const prevLastNotMyMessageIdRef = useRef<string | null>(null)
  const [currentCursor, setCurrentCursor] = useState<null | number>(null)
  const [messages, setMessages] = useState<ClientChatMessage[]>([])
  const [lastNotMyMessageId, setLastNotMyMessageId] = useState<string | null>(null)
  const { actualizeUnresolvedChatsCounter } = useUnresolvedChatsCounterActiualizer()

  const trpcUtils = trpc.useUtils()
  const createMessageTrpc =
    viewerType === 'trader'
      ? trpc.createChatMessageForTrader
      : viewerType === 'merchantMember'
        ? trpc.createChatMessageForMerchantMember
        : viewerType === 'admin'
          ? trpc.createChatMessageForAdmin
          : viewerType === 'merchantCustomer'
            ? (trpc.createChatMessageForMerchantCustomer as typeof trpc.createChatMessageForAdmin)
            : (trpc.createChatMessageForMerchantCustomer as typeof trpc.createChatMessageForAdmin)
  const createMessage = createMessageTrpc.useMutation()
  const zCreateMessageEndpointInput =
    viewerType === 'trader'
      ? zCreateChatMessageForTraderEndpointInput
      : viewerType === 'merchantMember'
        ? zCreateChatMessageForMerchantMemberEndpointInput
        : viewerType === 'admin'
          ? zCreateChatMessageForAdminEndpointInput
          : viewerType === 'merchantCustomer'
            ? (zCreateChatMessageForMerchantCustomerEndpointInput as never as typeof zCreateChatMessageForAdminEndpointInput)
            : (zCreateChatMessageForMerchantCustomerEndpointInput as never as typeof zCreateChatMessageForAdminEndpointInput)

  const resolveChatTrpc =
    viewerType === 'trader'
      ? trpc.resolveChatForTrader
      : viewerType === 'merchantMember'
        ? trpc.resolveChatForMerchantMember
        : viewerType === 'admin'
          ? trpc.resolveChatForAdmin
          : viewerType === 'merchantCustomer'
            ? (trpc.resolveChatForMerchantCustomer as typeof trpc.resolveChatForAdmin)
            : (trpc.resolveChatForMerchantCustomer as typeof trpc.resolveChatForAdmin)
  const resolveChat = resolveChatTrpc.useMutation()
  const zResolveChatEndpointInput =
    viewerType === 'trader'
      ? zResolveChatForTraderEndpointInput
      : viewerType === 'merchantMember'
        ? zResolveChatForMerchantMemberEndpointInput
        : viewerType === 'admin'
          ? zResolveChatForAdminEndpointInput
          : viewerType === 'merchantCustomer'
            ? (zResolveChatForMerchantCustomerEndpointInput as never as typeof zResolveChatForAdminEndpointInput)
            : (zResolveChatForMerchantCustomerEndpointInput as never as typeof zResolveChatForAdminEndpointInput)

  const utilsQuery =
    viewerType === 'trader'
      ? trpcUtils.getChatWithMessagesForTrader
      : viewerType === 'merchantMember'
        ? trpcUtils.getChatWithMessagesForMerchantMember
        : viewerType === 'admin'
          ? trpcUtils.getChatWithMessagesForAdmin
          : viewerType === 'merchantCustomer'
            ? (trpcUtils.getChatWithMessagesForMerchantCustomer as typeof trpcUtils.getChatWithMessagesForAdmin)
            : (trpcUtils.getChatWithMessagesForMerchantCustomer as typeof trpcUtils.getChatWithMessagesForAdmin)
  const usualQuery =
    viewerType === 'trader'
      ? trpc.getChatWithMessagesForTrader
      : viewerType === 'merchantMember'
        ? trpc.getChatWithMessagesForMerchantMember
        : viewerType === 'admin'
          ? trpc.getChatWithMessagesForAdmin
          : viewerType === 'merchantCustomer'
            ? (trpc.getChatWithMessagesForMerchantCustomer as typeof trpc.getChatWithMessagesForAdmin)
            : (trpc.getChatWithMessagesForMerchantCustomer as typeof trpc.getChatWithMessagesForAdmin)

  const actualize = useCallback(async () => {
    const result = await utilsQuery.fetch({
      chatId,
      dealId,
      dealSecret,
      currentCursor,
    })
    utilsQuery.setData(
      {
        chatId,
        dealId,
        dealSecret,
      },
      (prevData) =>
        !prevData
          ? result
          : {
              ...prevData,
              chat: result.chat,
            }
    )
    if (result.messages.length) {
      setMessages((prevMessages) => {
        return [...result.messages, ...prevMessages]
      })
      setCurrentCursor(result.messages[0].serialNumber)
    }
    if (result.chat) {
      utilsQuery.setData(
        {
          chatId,
          dealId,
          dealSecret,
        },
        (prevData) =>
          !prevData
            ? result
            : {
                ...prevData,
                chat: result.chat,
              }
      )
    }
  }, [chatId, currentCursor, dealId, dealSecret, setMessages, messages[0]?.id])

  const { isLoading, data, error } = usualQuery.useQuery({
    chatId,
    dealId,
    dealSecret,
  })
  const chat = data?.chat
  const chatUnresolved =
    viewerType === 'trader'
      ? chat?.unresolvedForTrader
      : viewerType === 'merchantMember'
        ? chat?.unresolvedForMerchantMember
        : viewerType === 'admin'
          ? chat?.unresolvedForAdmin
          : // eslint-disable-next-line no-unneeded-ternary
            viewerType === 'merchantCustomer'
            ? false
            : false

  const formyCreateMessage = useFormy({
    initialValues: {
      chatId,
      dealId,
      dealSecret,
      text: '',
      uploads: [],
    },
    resetOnSuccess: true,
    validationSchema: zCreateMessageEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      await createMessage.mutateAsync(valuesInput)
      await Promise.all([actualizeUnresolvedChatsCounter(), actualize()])
    },
    successMessage: false,
  })

  const formyResolveChat = useFormy({
    initialValues: {
      chatId,
      dealId,
      dealSecret,
    },
    resetOnSuccess: true,
    validationSchema: zResolveChatEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      await resolveChat.mutateAsync(valuesInput)
      await Promise.all([actualizeUnresolvedChatsCounter(), actualize()])
    },
    successMessage: false,
  })

  useEffect(() => {
    if (data) {
      setMessages(data.messages)
      setCurrentCursor(data.messages[0]?.serialNumber || null)
    }
  }, [data?.messages[0]?.id])

  useEffect(() => {
    const lastNotMyMessage = messages.find((message) => !message.isMy)
    if (lastNotMyMessage) {
      setLastNotMyMessageId(lastNotMyMessage.id)
    }
  }, [messages[0]?.id])

  useEffect(() => {
    if (lastNotMyMessageId && lastNotMyMessageId !== prevLastNotMyMessageIdRef.current) {
      prevLastNotMyMessageIdRef.current = lastNotMyMessageId
      if (!initial) {
        if (viewerType === 'merchantCustomer') {
          beep()
          void formyResolveChat.submitForm()
        }
      }
      setInitial(false)
    }
  }, [lastNotMyMessageId])

  useEffect(() => {
    const interval = setInterval(() => {
      void actualize()
    }, 3_000)
    return () => clearInterval(interval)
  }, [actualize])

  return (
    <Segment title="Переписка" size="m">
      {isLoading ? (
        <div>Загрузка...</div>
      ) : error ? (
        <div>Ошибка загрузки: {error.message}</div>
      ) : (
        <>
          <div className={css.input}>
            <FormItems as="form" {...formyCreateMessage.formProps}>
              <Textary
                onJustEnter={() => {
                  void formyCreateMessage.submitForm()
                }}
                label="Текст сообщения"
                {...formyCreateMessage.getFieldProps('text')}
              />
              <UploadsToS3 {...formyCreateMessage.getFieldProps('uploads')} label="Файлы" />
              <Buttons>
                <Button {...formyCreateMessage.buttonProps} type="submit">
                  Отправить
                </Button>
                {chatUnresolved && (
                  <Button
                    {...formyResolveChat.buttonProps}
                    onClick={() => {
                      void formyResolveChat.submitForm()
                    }}
                    type="button"
                  >
                    Пометить чат как просмотренный
                  </Button>
                )}
              </Buttons>
            </FormItems>
          </div>
          <div className={css.messages}>
            {messages.map((message) => (
              <div key={message.id} className={css.message}>
                <div className={css.author}>{message.isMy ? 'Вы' : toHumanChatMessageAuthor(message)}</div>
                <div className={css.date}>{formatDate(message.createdAt, 'dd.MM.yyyy HH:mm')}</div>
                <div className={css.text}>{message.text}</div>
                {!!message.uploads.length && (
                  <div className={css.uploads}>
                    {message.uploads.map((s3Key) => (
                      <a
                        key={s3Key}
                        className={css.uploadLink}
                        target="_blank"
                        href={ctx.getS3UploadUrl(s3Key)}
                        rel="noreferrer"
                      >
                        {getS3UploadName(s3Key)}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </Segment>
  )
}
