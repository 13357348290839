import type { TraderAutomatorLogStatus as PrismaTraderAutomatorLogStatus } from '@/backend/src/services/other/prisma.js'
import type { CheckIfArraySatisfies } from 'svag-types'
import { z } from 'zod'

export const traderAutomatorLogStatuses = [
  'sourceNotMatched',
  'inputNotMatched',
  'customerDepositNotFound',
  'customerDepositMoreThanOne',
  'applyingError',
  'success',
  'successAfterDeclare',
  'customerDepositNotActivePaymentMethod',
] as const satisfies PrismaTraderAutomatorLogStatus[]
export const zTraderAutomatorLogStatus = z.enum(traderAutomatorLogStatuses)
export type TraderAutomatorLogStatus = (typeof traderAutomatorLogStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: CheckIfArraySatisfies<TraderAutomatorLogStatus, PrismaTraderAutomatorLogStatus> = true

export const toHumanTraderAutomatorLogStatus = (status: TraderAutomatorLogStatus) => {
  return {
    sourceNotMatched: 'Источник уведомления не подходит',
    inputNotMatched: 'Входные данные не подходят',
    customerDepositNotFound: 'Подходящий клиентский депозит не найден',
    customerDepositNotActivePaymentMethod: 'Клиентский депозит не содержит активных методов оплаты',
    customerDepositMoreThanOne: 'Найдено более одного подходящего клиентского депозита',
    applyingError: 'Ошибка во время попытки подтвердить клиентский депозит',
    success: 'Клиентский депозит подтверждён успешно',
    successAfterDeclare: 'Клиентский депозит подтверждён успешно после объявления',
  }[status]
}
