import { iconsSources } from '@/webapp/src/lib/icons.js'
import { uinityConfig } from '@/webapp/src/lib/uninty.config.js'
import { createComponents } from '@uinity/react-dom'

export const {
  Button,
  Buttons,
  Link,
  Icon,
  Layout,
  SplashScreen,
  NProgress,
  ProgressLine,
  Disabler,
  Block,
  Grid,
  WithPopover,
  Modal,
  Text,
  RichText,
  Loader,
  ButtonLikeSelect,
  Textfield,
  ContextMenuItem,
  Select,
  Textarea,
  FormItem,
  FormItems,
  ControlIcon,
  AssignedElement,
  Informer,
  Toast,
  Card,
  Segment,
  Segments,
  Table,
  LabeledValues,
  ToggleButton,
  ToggleButtons,
  Tab,
  ToggleSwitch,
  Checkbox,
  Checkboxes,
  Radiobutton,
  Radiobuttons,
  Divider,
  Badge,
  Indicator,
  Avatar,
  DelimitedLine,
} = createComponents({
  uinityConfig,
  iconsSources,
  blankAvatarSrc: '/avatar.svg',
  dropdownIconSrc: iconsSources.chevronDown,
})
