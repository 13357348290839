import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zCancelProcessingCustomerWithdrawForTraderEndpointInput } from '@/general/src/customerWithdraw/routes/cancelProcessingCustomerWithdrawForTrader/input.js'
import { zCompleteCustomerWithdrawForTraderEndpointInput } from '@/general/src/customerWithdraw/routes/completeCustomerWithdrawForTrader/input.js'
import {
  toHumanCustomerWithdrawCancellationReason,
  toHumanCustomerWithdrawStatus,
} from '@/general/src/customerWithdraw/utils.shared.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { traderCustomerWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { withMessage } from '@/webapp/src/lib/utils.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

const Complete = ({
  customerWithdraw,
}: {
  customerWithdraw: TrpcRouterOutput['getCustomerWithdrawForTrader']['customerWithdraw']
}) => {
  const completeCustomerWithdrawForTrader = trpc.completeCustomerWithdrawForTrader.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      customerWithdrawId: customerWithdraw.id,
    },
    validationSchema: zCompleteCustomerWithdrawForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerWithdraw } = await completeCustomerWithdrawForTrader.mutateAsync(valuesInput)
      trpcUtils.getCustomerWithdrawForTrader.setData(
        { customerWithdrawSerialNumber: customerWithdraw.serialNumber },
        { customerWithdraw }
      )
    },
    successMessage: 'Клиентский виздрав успешно завершён',
    resetOnSuccess: false,
  })
  if (!customerWithdraw.customerPaymentMethodData) {
    return <p>Нет платёжных методов, обратитесь в администрацию</p>
  }
  return (
    <FormItems as="form" {...formy.formProps}>
      <PaymentMethodCard paymentMethod={customerWithdraw.customerPaymentMethodData} />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Я оплатил
        </Button>
      </Buttons>
    </FormItems>
  )
}

const CancelProcessing = ({
  customerWithdraw,
}: {
  customerWithdraw: TrpcRouterOutput['getCustomerWithdrawForTrader']['customerWithdraw']
}) => {
  const cancelProcessingCustomerWithdrawForTrader = trpc.cancelProcessingCustomerWithdrawForTrader.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      customerWithdrawId: customerWithdraw.id,
      message: '',
    },
    validationSchema: zCancelProcessingCustomerWithdrawForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerWithdraw } = await cancelProcessingCustomerWithdrawForTrader.mutateAsync(valuesInput)
      trpcUtils.getCustomerWithdrawForTrader.setData(
        { customerWithdrawSerialNumber: customerWithdraw.serialNumber },
        { customerWithdraw }
      )
    },
    successMessage: 'Клиентский виздрав успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина отмены" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const TraderCustomerWithdrawViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(traderCustomerWithdrawViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerWithdrawForTrader.useQuery(
      {
        customerWithdrawSerialNumber: +routeParams.customerWithdrawSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerWithdraw.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerWithdraw: queryResult.data.customerWithdraw,
  }),
})(({ customerWithdraw }) => {
  return (
    <Segment title={`Клиентский виздрав #${customerWithdraw.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanCustomerWithdrawStatus(customerWithdraw.status)],
            ['Сумма', toMoney({ amount: customerWithdraw.dealAmount, currency: customerWithdraw.dealCurrency })],
            [
              'Платёжный метод',
              customerWithdraw.paymentMethodType && toHumanPaymentMethodType(customerWithdraw.paymentMethodType),
            ],
            ['Сумма в USDT', toMoney({ amount: customerWithdraw.amountUsdt, currency: 'usdt' })],
            [
              'Вознаграждение в USDT',
              !!customerWithdraw.traderInterestUsdt &&
                toMoney({ amount: customerWithdraw.traderInterestUsdt, currency: 'usdt' }),
            ],
            [
              'Вознаграждение в %',
              !!customerWithdraw.traderInterestShare &&
                integerWithDecimalsToAmountString({ amount: customerWithdraw.traderInterestShare }) + '%',
            ],
            [
              'Изначальное вознаграждение в USDT',
              customerWithdraw.traderInterestUsdt !== customerWithdraw.traderInitialInterestUsdt &&
              !!customerWithdraw.traderInitialInterestUsdt
                ? toMoney({ amount: customerWithdraw.traderInitialInterestUsdt, currency: 'usdt' })
                : null,
              'Мерчант платит мало, поэтому пришлось урезать',
            ],
            [
              'Изначальное вознаграждение в %',
              customerWithdraw.traderInterestUsdt !== customerWithdraw.traderInitialInterestUsdt &&
              !!customerWithdraw.traderInitialInterestShare
                ? integerWithDecimalsToAmountString({ amount: customerWithdraw.traderInitialInterestShare }) + '%'
                : null,
              'Мерчант платит мало, поэтому пришлось урезать',
            ],
            ['Создание', formatDate(customerWithdraw.createdAt, 'dd.MM.yyyy HH:mm')],
            ['Взят в работу', customerWithdraw.pickedAt && formatDate(customerWithdraw.pickedAt, 'dd.MM.yyyy HH:mm')],
            ['Оплачено', customerWithdraw.completedAt && formatDate(customerWithdraw.completedAt, 'dd.MM.yyyy HH:mm')],
            ['Отменено', customerWithdraw.cancelledAt && formatDate(customerWithdraw.cancelledAt, 'dd.MM.yyyy HH:mm')],
            [
              'Начало спора',
              customerWithdraw.disputeStartedAt && formatDate(customerWithdraw.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Завершение спора',
              customerWithdraw.disputeCompletedAt &&
                formatDate(customerWithdraw.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отмена спора',
              customerWithdraw.disputeCancelledAt &&
                formatDate(customerWithdraw.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Длительность в минутах',
              customerWithdraw.finishedAt &&
                differenceInMinutes(customerWithdraw.finishedAt, customerWithdraw.createdAt),
            ],
          ]}
        />
        {customerWithdraw.status === 'processing' ? (
          <>
            <Segment
              title="Ваша задача"
              size="m"
              desc="Сделать перевод по указанным реквизитам и нажать на кнопку «Я оплатил»"
            >
              <Complete customerWithdraw={customerWithdraw} />
            </Segment>
            <Segment title="Отмена сделки" size="m" desc="Вы можете отменить сделку на этом этапе">
              <CancelProcessing customerWithdraw={customerWithdraw} />
            </Segment>
          </>
        ) : customerWithdraw.status === 'completed' ? (
          <Segment title="Сделка успешно завершена" size="m" />
        ) : customerWithdraw.status === 'cancelled' ? (
          <Segment
            title="Сделка была отменена"
            size="m"
            desc={toHumanCustomerWithdrawCancellationReason(
              customerWithdraw.cancellationCode,
              customerWithdraw.cancellationMessage
            )}
          />
        ) : customerWithdraw.status === 'disputing' ? (
          <Segment
            title="Идёт спор"
            size="m"
            desc={withMessage(`Администрация разбирается в споре`, customerWithdraw.disputeStartMessage)}
          />
        ) : customerWithdraw.status === 'refunded' ? (
          <Segment title="Средства по сделке были возвращены" size="m" />
        ) : null}
        <Chat chatId={customerWithdraw.chatId} viewerType="trader" />
      </Block>
    </Segment>
  )
})
