import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zDeclareCustomerDepositForMerchantCustomerEndpointInput } from '@/general/src/customerDeposit/routes/declareCustomerDepositForMerchantCustomer/input.js'
import {
  toHumanCustomerDepositCancellationReason,
  toHumanCustomerDepositStatus,
} from '@/general/src/customerDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { MerchantCustomerLayout } from '@/webapp/src/components/layout/MerchantCustomerLayout/index.js'
import { Deadline } from '@/webapp/src/components/main/Deadline/index.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { merchantCustomerCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { withMessage } from '@/webapp/src/lib/utils.js'
import { differenceInMinutes } from 'date-fns'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'

const Declare = ({
  customerDeposit,
}: {
  customerDeposit: TrpcRouterOutput['getCustomerDepositForMerchantCustomer']['customerDeposit']
}) => {
  const declareCustomerDepositForMerchantCustomer = trpc.declareCustomerDepositForMerchantCustomer.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      customerDepositId: customerDeposit.id,
      customerDepositSecret: customerDeposit.secret,
    },
    validationSchema: zDeclareCustomerDepositForMerchantCustomerEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await declareCustomerDepositForMerchantCustomer.mutateAsync(valuesInput)
      trpcUtils.getCustomerDepositForMerchantCustomer.setData(
        { customerDepositId: customerDeposit.id, customerDepositSecret: customerDeposit.secret },
        { customerDeposit }
      )
    },
    successMessage: 'Сделка переведена в статус проверки',
    resetOnSuccess: false,
  })
  if (!customerDeposit.traderPaymentMethodData) {
    return <p>Нет платёжных методов, обратитесь в администрацию</p>
  }
  return (
    <FormItems as="form" {...formy.formProps}>
      <PaymentMethodCard paymentMethod={customerDeposit.traderPaymentMethodData} />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Я оплатил
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const MerchantCustomerCustomerDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: MerchantCustomerLayout,
  useQuery: () => {
    const { routeParams } = useRouteParams(merchantCustomerCustomerDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerDepositForMerchantCustomer.useQuery(
      {
        customerDepositId: routeParams.customerDepositId,
        customerDepositSecret: routeParams.customerDepositSecret,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerDeposit: queryResult.data.customerDeposit,
  }),
})(({ customerDeposit }) => {
  const trpcUtils = trpc.useUtils()
  return (
    <Segment title={`Перевод денег #${customerDeposit.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanCustomerDepositStatus(customerDeposit.status)],
            ['Сумма', toMoney({ amount: customerDeposit.dealAmount, currency: customerDeposit.dealCurrency })],
            [
              'Платёжный метод',
              customerDeposit.paymentMethodType && toHumanPaymentMethodType(customerDeposit.paymentMethodType),
            ],
            ['Создание', formatDate(customerDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
            ['Взят в работу', customerDeposit.pickedAt && formatDate(customerDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
            ['Оплачено', customerDeposit.declaredAt && formatDate(customerDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
            ['Проверено', customerDeposit.completedAt && formatDate(customerDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
            ['Отменено', customerDeposit.cancelledAt && formatDate(customerDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
            [
              'Начало спора',
              customerDeposit.disputeStartedAt && formatDate(customerDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Завершение спора',
              customerDeposit.disputeCompletedAt && formatDate(customerDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отмена спора',
              customerDeposit.disputeCancelledAt && formatDate(customerDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Длительность в минутах',
              customerDeposit.finishedAt && differenceInMinutes(customerDeposit.finishedAt, customerDeposit.createdAt),
            ],
          ]}
        />
        {customerDeposit.status === 'picking' ? (
          <Segment title="Ваша задача" size="m" desc="Дождаться пока исполнитель возьмётся за эту сделку" />
        ) : customerDeposit.status === 'processing' ? (
          <>
            <Segment
              title="Ваша задача"
              size="m"
              desc="Сделать перевод по указанным реквизитам и нажать на кнопку «Я оплатил»"
            >
              <Declare customerDeposit={customerDeposit} />
            </Segment>
            <Deadline
              deadline={customerDeposit.willBeCancelledBecauseNotDeclaredAt}
              message="Если вы не успеете оплатить и нажать кнопку «Я оплатил» в течние этого времени, сделка будет отменена"
              callback={() => {
                void trpcUtils.getCustomerDepositForMerchantCustomer.refetch({
                  customerDepositId: customerDeposit.id,
                  customerDepositSecret: customerDeposit.secret,
                })
              }}
            />
          </>
        ) : customerDeposit.status === 'verifying' ? (
          <Segment title="Ваша задача" size="m" desc="Дождаться, пока исполнитель подтвердит получение средств" />
        ) : customerDeposit.status === 'completed' ? (
          <Segment title="Сделка успешно завершена" size="m" desc="Вы можете вернуться на сайт интернет-магазина">
            <Buttons>
              <Button as="a" href={customerDeposit.successUrl}>
                Вернуться на сайт
              </Button>
            </Buttons>
          </Segment>
        ) : customerDeposit.status === 'cancelled' ? (
          <Segment
            title="Сделка была отменена"
            size="m"
            desc={toHumanCustomerDepositCancellationReason(
              customerDeposit.cancellationCode,
              customerDeposit.cancellationMessage
            )}
          >
            <Buttons>
              <Button as="a" href={customerDeposit.failUrl}>
                Вернуться на сайт
              </Button>
            </Buttons>
          </Segment>
        ) : customerDeposit.status === 'disputing' ? (
          <Segment
            title="Идёт спор"
            size="m"
            desc={withMessage(`Администрация разбирается в споре`, customerDeposit.disputeStartMessage)}
          />
        ) : null}
        <Chat
          chatId={customerDeposit.chatId}
          viewerType="merchantCustomer"
          dealId={customerDeposit.id}
          dealSecret={customerDeposit.secret}
        />
      </Block>
    </Segment>
  )
})
