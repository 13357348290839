import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ApiDocsSection } from '@/general/src/api/docs/ApiDocsSection.js'

export const ApiDocsGetPaymentMethods = ({
  merchant,
}: {
  merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null
}) => {
  return (
    <ApiDocsSection
      merchant={merchant}
      title="Получение доступных методов платежей"
      method="GET"
      path="/api/get-payment-methods/v1"
      responseExample={{
        paymentMethodsGroups: [
          {
            name: 'card',
            title: 'Банковская карта',
            paymentMethodsTypes: [
              {
                name: 'sberbankCard',
                title: 'Сбербанк карта',
              },
              {
                name: 'tinkoffCard',
                title: 'Тинькофф карта',
              },
            ],
          },
          {
            name: 'sbp',
            title: 'СБП',
            paymentMethodsTypes: [
              {
                name: 'sberbankSbp',
                title: 'Сбербанк СБП',
              },
              {
                name: 'tinkoffSbp',
                title: 'Тинькофф СБП',
              },
              {
                name: 'akbarsSbp',
                title: 'Ак Барс СБП',
              },
            ],
          },
        ],
      }}
    />
  )
}
