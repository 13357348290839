import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zCancelPickingMerchantDepositForMerchantMemberEndpointInput } from '@/general/src/merchantDeposit/routes/cancelPickingMerchantDepositForMerchantMember/input.js'
import { zCancelProcessingMerchantDepositForMerchantMemberEndpointInput } from '@/general/src/merchantDeposit/routes/cancelProcessingMerchantDepositForMerchantMember/input.js'
import { zDeclareMerchantDepositForMerchantMemberEndpointInput } from '@/general/src/merchantDeposit/routes/declareMerchantDepositForMerchantMember/input.js'
import {
  toHumanMerchantDepositCancellationReason,
  toHumanMerchantDepositStatus,
} from '@/general/src/merchantDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { PaymentMethodCard } from '@/general/src/paymentMethod/PaymentMethod.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { merchantMerchantDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { differenceInMinutes } from 'date-fns'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'

const Declare = ({
  merchantDeposit,
}: {
  merchantDeposit: TrpcRouterOutput['getMerchantDepositForMerchantMember']['merchantDeposit']
}) => {
  const declareMerchantDepositForMerchantMember = trpc.declareMerchantDepositForMerchantMember.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantDepositId: merchantDeposit.id,
      garantexCode: '',
    },
    validationSchema: zDeclareMerchantDepositForMerchantMemberEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantDeposit } = await declareMerchantDepositForMerchantMember.mutateAsync(valuesInput)
      trpcUtils.getMerchantDepositForMerchantMember.setData(
        { merchantDepositSerialNumber: merchantDeposit.serialNumber },
        { merchantDeposit }
      )
    },
    successMessage: 'Мерчантский депозит переведён в статус проверки',
    resetOnSuccess: false,
  })
  if (!merchantDeposit.companyPaymentMethodData) {
    return <p>Нет платёжных методов, обратитесь в администрацию</p>
  }
  return (
    <FormItems as="form" {...formy.formProps}>
      <PaymentMethodCard paymentMethod={merchantDeposit.companyPaymentMethodData} />
      {merchantDeposit.paymentMethodType === 'garantex' && (
        <Textfieldy label="Код гарантекс" {...formy.getFieldProps('garantexCode')} />
      )}
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Я оплатил
        </Button>
      </Buttons>
    </FormItems>
  )
}

const CancelPicking = ({
  merchantDeposit,
}: {
  merchantDeposit: TrpcRouterOutput['getMerchantDepositForMerchantMember']['merchantDeposit']
}) => {
  const cancelPickingMerchantDepositForMerchantMember = trpc.cancelPickingMerchantDepositForMerchantMember.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantDepositId: merchantDeposit.id,
      message: '',
    },
    validationSchema: zCancelPickingMerchantDepositForMerchantMemberEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantDeposit } = await cancelPickingMerchantDepositForMerchantMember.mutateAsync(valuesInput)
      trpcUtils.getMerchantDepositForMerchantMember.setData(
        { merchantDepositSerialNumber: merchantDeposit.serialNumber },
        { merchantDeposit }
      )
    },
    successMessage: 'Мерчантский депозит успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина отмены" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

const CancelProcessing = ({
  merchantDeposit,
}: {
  merchantDeposit: TrpcRouterOutput['getMerchantDepositForMerchantMember']['merchantDeposit']
}) => {
  const cancelProcessingMerchantDepositForMerchantMember =
    trpc.cancelProcessingMerchantDepositForMerchantMember.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantDepositId: merchantDeposit.id,
      message: '',
    },
    validationSchema: zCancelProcessingMerchantDepositForMerchantMemberEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantDeposit } = await cancelProcessingMerchantDepositForMerchantMember.mutateAsync(valuesInput)
      trpcUtils.getMerchantDepositForMerchantMember.setData(
        { merchantDepositSerialNumber: merchantDeposit.serialNumber },
        { merchantDeposit }
      )
    },
    successMessage: 'Мерчантский депозит успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина отмены" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const MerchantMerchantDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(merchantMerchantDepositViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getMerchantDepositForMerchantMember.useQuery(
      {
        merchantDepositSerialNumber: +routeParams.merchantDepositSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.merchantDeposit.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    merchantDeposit: queryResult.data.merchantDeposit,
  }),
})(({ merchantDeposit }) => {
  return (
    <Segment title={`Мерчантский депозит #${merchantDeposit.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanMerchantDepositStatus(merchantDeposit.status)],
            ['Сумма в USDT', toMoney({ amount: merchantDeposit.amountUsdt, currency: 'usdt' })],
            [
              'Платёжный метод',
              merchantDeposit.paymentMethodType && toHumanPaymentMethodType(merchantDeposit.paymentMethodType),
            ],
            [
              'Номер кошелька',
              merchantDeposit.companyPaymentMethodData?.group === 'crypto'
                ? merchantDeposit.companyPaymentMethodData.wallet
                : null,
            ],
            ['Код гарантекс', merchantDeposit.garantexCode],
            ['Админ', merchantDeposit.admin?.serialNumber && `#${merchantDeposit.admin.serialNumber}`],
            ['Мерчант мембер', `#${merchantDeposit.merchantMember.serialNumber}`],
            ['Создание', formatDate(merchantDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
            ['Взят в работу', merchantDeposit.pickedAt && formatDate(merchantDeposit.pickedAt, 'dd.MM.yyyy HH:mm')],
            ['Оплачено', merchantDeposit.declaredAt && formatDate(merchantDeposit.declaredAt, 'dd.MM.yyyy HH:mm')],
            ['Проверено', merchantDeposit.completedAt && formatDate(merchantDeposit.completedAt, 'dd.MM.yyyy HH:mm')],
            ['Отменено', merchantDeposit.cancelledAt && formatDate(merchantDeposit.cancelledAt, 'dd.MM.yyyy HH:mm')],
            [
              'Начало спора',
              merchantDeposit.disputeStartedAt && formatDate(merchantDeposit.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Завершение спора',
              merchantDeposit.disputeCompletedAt && formatDate(merchantDeposit.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отмена спора',
              merchantDeposit.disputeCancelledAt && formatDate(merchantDeposit.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Длительность в минутах',
              merchantDeposit.finishedAt && differenceInMinutes(merchantDeposit.finishedAt, merchantDeposit.createdAt),
            ],
          ]}
        />
        {merchantDeposit.status === 'picking' ? (
          <>
            <Segment title="Ваша задача" size="m" desc="Дождаться пока админ возьмётся за ваш депозит" />
            <Segment title="Отмена сделки" size="m" desc="Вы можете отменить сделку на этом этапе">
              <CancelPicking merchantDeposit={merchantDeposit} />
            </Segment>
          </>
        ) : merchantDeposit.status === 'processing' ? (
          <>
            <Segment
              title="Ваша задача"
              size="m"
              desc="Сделать перевод по указанным реквизитам и нажать на кнопку «Я оплатил»"
            >
              <Declare merchantDeposit={merchantDeposit} />
            </Segment>
            <Segment title="Отмена сделки" size="m" desc="Вы можете отменить сделку на этом этапе">
              <CancelProcessing merchantDeposit={merchantDeposit} />
            </Segment>
          </>
        ) : merchantDeposit.status === 'verifying' ? (
          <Segment
            title="Ваша задача"
            size="m"
            desc="Дождаться, пока админ подтвердит получение средств. После этого деньги отобразятся на свободном балансе мерчанта"
          />
        ) : merchantDeposit.status === 'completed' ? (
          <Segment title="Сделка успешно завершена" size="m" />
        ) : merchantDeposit.status === 'cancelled' ? (
          <Segment
            title="Сделка была отменена"
            size="m"
            desc={toHumanMerchantDepositCancellationReason(
              merchantDeposit.cancellationCode,
              merchantDeposit.cancellationMessage
            )}
          />
        ) : null}
        <Chat chatId={merchantDeposit.chatId} viewerType="merchantMember" />
      </Block>
    </Segment>
  )
})
