import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { zUpdateCompanyAvailabilityForAdminEndpointInput } from '@/general/src/company/routes/updateCompanyAvailabilityForAdmin/input.js'
import { Switchy, useFormy } from '@/webapp/src/lib/formy.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'

export const CompanyAvailability = ({ company }: { company: TrpcRouterOutput['getCompany']['company'] }) => {
  const updateCompanyAvailabilityForAdmin = trpc.updateCompanyAvailabilityForAdmin.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      available: company.available,
    },
    validationSchema: zUpdateCompanyAvailabilityForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await updateCompanyAvailabilityForAdmin.mutateAsync({
        ...valuesInput,
      })
      trpcUtils.getCompany.setData(undefined, res)
    },
    successMessage: 'Доступность обновлена',
  })
  return (
    <Segment title="Доступность сервиса" size="m">
      <FormItems as="form" {...formy.formProps}>
        <Switchy
          {...formy.getFieldProps('available')}
          label="Доступен"
          optionLabel="Да"
          hint="Если выключить доступность, то никто кроме админов не сможет пользоваться сервисом"
        />
        <Buttons>
          <Button {...formy.buttonProps} type="submit">
            Сохранить
          </Button>
        </Buttons>
      </FormItems>
    </Segment>
  )
}
