import { pageData } from './pageData.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import {
  toHumanCustomerWithdrawCancellationReason,
  toHumanCustomerWithdrawStatus,
} from '@/general/src/customerWithdraw/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { merchantCustomerWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { withMessage } from '@/webapp/src/lib/utils.js'
import { differenceInMinutes, formatDate } from 'date-fns'
import { useEffect, useState } from 'react'

export const MerchantCustomerWithdrawViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(merchantCustomerWithdrawViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerWithdrawForMerchantMember.useQuery(
      {
        customerWithdrawSerialNumber: +routeParams.customerWithdrawSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerWithdraw.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerWithdraw: queryResult.data.customerWithdraw,
  }),
})(({ customerWithdraw }) => {
  return (
    <Segment title={`Клиентский виздрав #${customerWithdraw.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanCustomerWithdrawStatus(customerWithdraw.status)],
            ['Сумма', toMoney({ amount: customerWithdraw.dealAmount, currency: customerWithdraw.dealCurrency })],
            [
              'Платёжный метод',
              customerWithdraw.paymentMethodType && toHumanPaymentMethodType(customerWithdraw.paymentMethodType),
            ],
            ['Сумма в USDT', toMoney({ amount: customerWithdraw.amountUsdt, currency: 'usdt' })],
            [
              'Комиссия мерчанта в USDT',
              !!customerWithdraw.merchantFeeUsdt &&
                toMoney({ amount: customerWithdraw.merchantFeeUsdt, currency: 'usdt' }),
            ],
            ['Создание', formatDate(customerWithdraw.createdAt, 'dd.MM.yyyy HH:mm')],
            ['Взят в работу', customerWithdraw.pickedAt && formatDate(customerWithdraw.pickedAt, 'dd.MM.yyyy HH:mm')],
            ['Проверено', customerWithdraw.completedAt && formatDate(customerWithdraw.completedAt, 'dd.MM.yyyy HH:mm')],
            ['Отменено', customerWithdraw.cancelledAt && formatDate(customerWithdraw.cancelledAt, 'dd.MM.yyyy HH:mm')],
            [
              'Начало спора',
              customerWithdraw.disputeStartedAt && formatDate(customerWithdraw.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Завершение спора',
              customerWithdraw.disputeCompletedAt &&
                formatDate(customerWithdraw.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отмена спора',
              customerWithdraw.disputeCancelledAt &&
                formatDate(customerWithdraw.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Длительность в минутах',
              customerWithdraw.finishedAt &&
                differenceInMinutes(customerWithdraw.finishedAt, customerWithdraw.createdAt),
            ],
          ]}
        />
        {customerWithdraw.status === 'completed' ? (
          <Segment title="Сделка успешно завершена" size="m" />
        ) : customerWithdraw.status === 'cancelled' ? (
          <Segment
            title="Сделка была отменена"
            size="m"
            desc={toHumanCustomerWithdrawCancellationReason(
              customerWithdraw.cancellationCode,
              customerWithdraw.cancellationMessage
            )}
          />
        ) : customerWithdraw.status === 'disputing' ? (
          <Segment
            title="Идёт спор"
            size="m"
            desc={withMessage(`Администрация разбирается в споре`, customerWithdraw.disputeStartMessage)}
          />
        ) : customerWithdraw.status === 'refunded' ? (
          <Segment title="Средства по сделке были возвращены" size="m" />
        ) : null}
        <Chat chatId={customerWithdraw.chatId} viewerType="merchantMember" />
      </Block>
    </Segment>
  )
})
