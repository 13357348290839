import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zDisputeStartCustomerWithdrawForMerchantCustomerEndpointInput } from '@/general/src/customerWithdraw/routes/disputeStartCustomerWithdrawForMerchantCustomer/input.js'
import {
  toHumanCustomerWithdrawCancellationReason,
  toHumanCustomerWithdrawStatus,
} from '@/general/src/customerWithdraw/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { MerchantCustomerLayout } from '@/webapp/src/components/layout/MerchantCustomerLayout/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { merchantCustomerCustomerWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { withMessage } from '@/webapp/src/lib/utils.js'
import { differenceInMinutes } from 'date-fns'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'

const DispiteStart = ({
  customerWithdraw,
}: {
  customerWithdraw: TrpcRouterOutput['getCustomerWithdrawForMerchantCustomer']['customerWithdraw']
}) => {
  const disputeStartCustomerWithdrawForMerchantCustomer =
    trpc.disputeStartCustomerWithdrawForMerchantCustomer.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      customerWithdrawId: customerWithdraw.id,
      customerWithdrawSecret: customerWithdraw.secret,
      message: '',
    },
    validationSchema: zDisputeStartCustomerWithdrawForMerchantCustomerEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerWithdraw } = await disputeStartCustomerWithdrawForMerchantCustomer.mutateAsync(valuesInput)
      trpcUtils.getCustomerWithdrawForMerchantCustomer.setData(
        { customerWithdrawId: customerWithdraw.id, customerWithdrawSecret: customerWithdraw.secret },
        { customerWithdraw }
      )
    },
    successMessage: 'Спор по клиентскому виздраву начат',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Начать спор
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const MerchantCustomerCustomerWithdrawViewPage = withPageWrapper({
  title: pageData.title,
  Layout: MerchantCustomerLayout,
  useQuery: () => {
    const { routeParams } = useRouteParams(merchantCustomerCustomerWithdrawViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getCustomerWithdrawForMerchantCustomer.useQuery(
      {
        customerWithdrawId: routeParams.customerWithdrawId,
        customerWithdrawSecret: routeParams.customerWithdrawSecret,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.customerWithdraw.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    customerWithdraw: queryResult.data.customerWithdraw,
  }),
})(({ customerWithdraw }) => {
  return (
    <Segment title={`Вывод денег #${customerWithdraw.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanCustomerWithdrawStatus(customerWithdraw.status)],
            ['Сумма', toMoney({ amount: customerWithdraw.dealAmount, currency: customerWithdraw.dealCurrency })],
            [
              'Платёжный метод',
              customerWithdraw.paymentMethodType && toHumanPaymentMethodType(customerWithdraw.paymentMethodType),
            ],
            ['Создание', formatDate(customerWithdraw.createdAt, 'dd.MM.yyyy HH:mm')],
            ['Взят в работу', customerWithdraw.pickedAt && formatDate(customerWithdraw.pickedAt, 'dd.MM.yyyy HH:mm')],
            ['Оплачено', customerWithdraw.completedAt && formatDate(customerWithdraw.completedAt, 'dd.MM.yyyy HH:mm')],
            ['Отменено', customerWithdraw.cancelledAt && formatDate(customerWithdraw.cancelledAt, 'dd.MM.yyyy HH:mm')],
            [
              'Начало спора',
              customerWithdraw.disputeStartedAt && formatDate(customerWithdraw.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Завершение спора',
              customerWithdraw.disputeCompletedAt &&
                formatDate(customerWithdraw.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отмена спора',
              customerWithdraw.disputeCancelledAt &&
                formatDate(customerWithdraw.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Длительность в минутах',
              customerWithdraw.finishedAt &&
                differenceInMinutes(customerWithdraw.finishedAt, customerWithdraw.createdAt),
            ],
          ]}
        />
        {customerWithdraw.status === 'picking' ? (
          <Segment title="Ваша задача" size="m" desc="Дождаться, пока исполнитель возьмётся за эту сделку" />
        ) : customerWithdraw.status === 'processing' ? (
          <Segment
            title="Ваша задача"
            size="m"
            desc="Дождаться, пока исполнитель сделает перевод средств по указанным реквизитам"
          />
        ) : customerWithdraw.status === 'completed' ? (
          <>
            <Segment
              title="Сделка успешно завершена"
              size="m"
              desc="Деньги были переведны по указанным реквизитам в ближайшие минуты уже должны быть у вас"
            >
              <Buttons>
                <Button as="a" href={customerWithdraw.successUrl}>
                  Вернуться на сайт
                </Button>
              </Buttons>
            </Segment>
            {customerWithdraw.isDisputable && (
              <Segment
                title="Деньги не приходят?"
                size="m"
                desc="Если деньги долго не приходят, вы можете начать спор, чтобы пришёл админ и разобрался"
              >
                <DispiteStart customerWithdraw={customerWithdraw} />
              </Segment>
            )}
          </>
        ) : customerWithdraw.status === 'cancelled' ? (
          <Segment
            title="Сделка была отменена"
            size="m"
            desc={toHumanCustomerWithdrawCancellationReason(
              customerWithdraw.cancellationCode,
              customerWithdraw.cancellationMessage
            )}
          >
            <Buttons>
              <Button as="a" href={customerWithdraw.failUrl}>
                Вернуться на сайт
              </Button>
            </Buttons>
          </Segment>
        ) : customerWithdraw.status === 'disputing' ? (
          <Segment
            title="Идёт спор"
            size="m"
            desc={withMessage(`Администрация разбирается в споре`, customerWithdraw.disputeStartMessage)}
          />
        ) : customerWithdraw.status === 'refunded' ? (
          <Segment title="Средства по сделке были возвращены" size="m" />
        ) : null}
        <Chat
          chatId={customerWithdraw.chatId}
          viewerType="merchantCustomer"
          dealId={customerWithdraw.id}
          dealSecret={customerWithdraw.secret}
        />
      </Block>
    </Segment>
  )
})
