import { ActiveMerchantDeposits } from './components/ActiveMerchantDeposits.js'
import { ActiveMerchantWithdraws } from './components/ActiveMerchantWithdraws.js'
import { ActiveTraderDeposits } from './components/ActiveTraderDeposits.js'
import { ActiveTraderInsuranceWithdraws } from './components/ActiveTraderInsuranceWithdraws.js'
import { AvailableMerchantDeposits } from './components/AvailableMerchantDeposits.js'
import { AvailableMerchantWithdraws } from './components/AvailableMerchantWithdraws.js'
import { AvailableTraderDeposits } from './components/AvailableTraderDeposits.js'
import { AvailableTraderInsuranceWithdraws } from './components/AvailableTraderInsuranceWithdraws.js'
import { DisputingCustomerDeposits } from './components/DisputingCustomerDeposits.js'
import { DisputingCustomerWithdraws } from './components/DisputingCustomerWithdraws.js'
import { DisputingMerchantWithdraws } from './components/DisputingMerchantWithdraws.js'
import { pageData } from './pageData.js'
import { toMoney } from '@/general/src/other/money.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'

export const AdminDashboardPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  setProps: ({ getAuthorizedAdminMe }) => ({
    me: getAuthorizedAdminMe(),
  }),
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getMe.useQuery(undefined, {
      refetchInterval: 3_000,
    }),
})(({ me }) => {
  return (
    <Block fcnw>
      <Segment title="Админский дашборд" size="m">
        <Segments>
          <Segment title="Баланс" size="s">
            <LabeledValues
              items={[
                ['Свободно', toMoney({ amount: me.admin.company.balanceUsdtFree, currency: 'usdt' })],
                ['Заморожено', toMoney({ amount: me.admin.company.balanceUsdtHolded, currency: 'usdt' })],
                ['Занято', toMoney({ amount: me.admin.company.balanceUsdtBusy, currency: 'usdt' })],
              ]}
            />
          </Segment>
          <Segment title="Споры по клиентским виздравам" size="s">
            <DisputingCustomerWithdraws me={me} />
          </Segment>
          <Segment title="Споры по клиентским депозитам" size="s">
            <DisputingCustomerDeposits me={me} />
          </Segment>
          <Segment title="Споры по мерчантским виздравам" size="s">
            <DisputingMerchantWithdraws me={me} />
          </Segment>
          <Segment title="Свободные мерчантские виздравы" size="s">
            <AvailableMerchantWithdraws me={me} />
          </Segment>
          <Segment title="Свободные мерчантские депозиты" size="s">
            <AvailableMerchantDeposits me={me} />
          </Segment>
          <Segment title="Свободные трейдерские депозиты" size="s">
            <AvailableTraderDeposits me={me} />
          </Segment>
          <Segment title="Свободные трейдерские страховые виздравы" size="s">
            <AvailableTraderInsuranceWithdraws me={me} />
          </Segment>
          <Segment title="Мои текущие мерчантские виздравы" size="s">
            <ActiveMerchantWithdraws />
          </Segment>
          <Segment title="Мои текущие мерчантские депозиты" size="s">
            <ActiveMerchantDeposits />
          </Segment>
          <Segment title="Мои текущие трейдерские депозиты" size="s">
            <ActiveTraderDeposits />
          </Segment>
          <Segment title="Мои текущие трейдерские страховые виздравы" size="s">
            <ActiveTraderInsuranceWithdraws />
          </Segment>
        </Segments>
      </Segment>
    </Block>
  )
})
