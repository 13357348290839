import { pageData } from './pageData.js'
import { toHumanMerchantDepositStatus } from '@/general/src/merchantDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminMerchantDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Segment, Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const AdminMerchantDepositListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  const queryResult = trpc.getMerchantDepositsForAdmin.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
    }
  )
  const merchantDeposits = queryResult.data?.pages.flatMap((page) => page.merchantDeposits) || []
  return (
    <Block fcnw>
      <Segment
        title="Все мерчантские депозиты"
        size="m"
        desc={<>Мерчантский депозит — это способ пополнения свободного баланса мерчанта</>}
      >
        {queryResult.isLoading ? (
          <p>Загрузка...</p>
        ) : !merchantDeposits.length ? (
          <p>Здесь пусто</p>
        ) : (
          <Table
            records={merchantDeposits}
            href={(record) => adminMerchantDepositViewRoute.get({ merchantDepositSerialNumber: record.serialNumber })}
            columns={[
              { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
              { heading: 'Статус', body: (record) => toHumanMerchantDepositStatus(record.status), width: 150 },
              {
                heading: 'Мерчант',
                body: (record) => `#${record.merchant.serialNumber}`,
                width: 150,
              },
              {
                heading: 'Дата',
                body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
                width: 150,
              },
              {
                heading: 'Метод',
                body: (record) => toHumanPaymentMethodType(record.paymentMethodType),
                width: 200,
              },
              {
                heading: 'Сумма',
                body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
                width: 100,
              },
              {
                heading: 'Сумма, ₮',
                body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
                width: 100,
              },
            ]}
            loadMore={() => {
              if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
                void queryResult.fetchNextPage()
              }
            }}
            hasMore={queryResult.hasNextPage}
          />
        )}
      </Segment>
    </Block>
  )
})
