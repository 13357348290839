export const ErrorPageComponent = (props: { title?: string; message?: string }) => {
  const title = props.title || 'Что-то пошло не так'
  const message = props.message || 'Попробуйте обновить страницу или вернуться на главную.'
  return (
    <div>
      <h1>{title}</h1>
      <p>{message}</p>
    </div>
  )
}
