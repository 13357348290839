import { zAmountIntegerWithDecimalsLimited } from '@/general/src/other/money.js'
import { zEmailRequired, zPhoneOptionalNullable, zStringRequired } from '@/general/src/other/validation.js'
import { z } from 'zod'

export const zCreateTraderForAdminEndpointInput = z.object({
  customerWithdrawInterestShare: zAmountIntegerWithDecimalsLimited(0, 500),
  customerDepositInterestShare: zAmountIntegerWithDecimalsLimited(0, 500),
  teamleadCustomerWithdrawBonusShare: zAmountIntegerWithDecimalsLimited(0, 500),
  teamleadCustomerDepositBonusShare: zAmountIntegerWithDecimalsLimited(0, 500),
  email: zEmailRequired,
  phone: zPhoneOptionalNullable,
  telegram: zStringRequired,
  name: zStringRequired,
  referrerSerialNumber: z.coerce.number().int().optional().nullable(),
})
