import { Segment } from '@/webapp/src/lib/uninty.components.js'
import { useEffect, useState } from 'react'

const getDifference = (deadline: Date) => {
  const now = new Date()
  const diff = Math.max(0, deadline.getTime() - now.getTime()) // Ensure diff is not negative
  const minutes = Math.floor(diff / 60_000)
  const seconds = Math.floor((diff % 60_000) / 1_000)

  const differenceString = (() => {
    if (!minutes) {
      return `${seconds} секунд`
    }
    return `${minutes} минут и ${seconds} секунд`
  })()
  const differenceSeconds = diff / 1_000
  return { differenceString, differenceSeconds }
}

export const Deadline = ({
  message,
  deadline,
  callback,
}: {
  message: string
  deadline: Date | null
  callback: () => any
}) => {
  const [diffStr, setDiffStr] = useState(deadline ? getDifference(deadline).differenceString : '0 секунд')

  useEffect(() => {
    const updateDifference = () => {
      if (!deadline) {
        return
      }
      const { differenceString, differenceSeconds } = getDifference(deadline)
      setDiffStr(differenceString)
      if (differenceSeconds <= 0) {
        callback()
      }
    }
    updateDifference() // Initial call to set the difference immediately
    const interval = setInterval(updateDifference, 1_000) // Update every second

    return () => clearInterval(interval) // Cleanup interval on component unmount
  }, [deadline])

  if (!deadline) {
    return null
  }

  return <Segment title={`Осталось ${diffStr}`} size="m" desc={message} />
}
