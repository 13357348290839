/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-component-props */
import css from './PaymentMethod.module.scss'
import { zUpdateCompanyPaymentMethodsForAdminEndpointInput } from '@/general/src/paymentMethod/routes/updateCompanyPaymentMethodsForAdmin/input.js'
import { zUpdateMerchantPaymentMethodsForMerchantMemberEndpointInput } from '@/general/src/paymentMethod/routes/updateMerchantPaymentMethodsForMerchantMember/input.js'
import { zUpdateMyPaymentMethodsForTraderEndpointInput } from '@/general/src/paymentMethod/routes/updateMyPaymentMethodsForTrader/input.js'
import type {
  ClientPaymentMethodForOwner,
  ClientPaymentMethodGeneral,
} from '@/general/src/paymentMethod/utils.server.js'
import type { PaymentMethodRecordUpsertDataInput } from '@/general/src/paymentMethod/utils.shared.js'
import {
  paymentMethodsGroupsExternal,
  paymentMethodsTypesByGroups,
  toHumanPaymentMethodGroup,
  toHumanPaymentMethodType,
} from '@/general/src/paymentMethod/utils.shared.js'
import { Selecty, Switchy, Textfieldy, useFormy, useFormyField } from '@/webapp/src/lib/formy.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import {
  Block,
  Button,
  Buttons,
  Card,
  FormItem,
  FormItems,
  LabeledValues,
  Segment,
} from '@/webapp/src/lib/uninty.components.js'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import type { Formy } from 'svag-formy'
import type { FormyInputPropsGeneral } from 'svag-formy/dist/utils.js'
import { prettifyBankCard, prettifyPhone } from 'svag-utils'

export const PaymentMethodCardsPicker = ({
  label,
  formy,
  name,
  hint,
  paymentMethods,
}: FormyInputPropsGeneral &
  React.ComponentProps<typeof FormItem> & {
    paymentMethods: ClientPaymentMethodForOwner[]
  }) => {
  const { error, value } = useFormyField({ formy, name })
  return (
    <FormItem label={label} hint={hint} error={error}>
      <Block fcnw g={10}>
        {paymentMethods.map((paymentMethod, i) => (
          <PaymentMethodCard
            key={i}
            checked={value === paymentMethod.id}
            onClick={() => {
              void formy.setFieldValue(name, paymentMethod.id)
            }}
            paymentMethod={paymentMethod}
          />
        ))}
      </Block>
    </FormItem>
  )
}

export const PaymentMethodCard = ({
  paymentMethod,
  checked,
  onClick,
  garantexCode,
}: {
  paymentMethod: ClientPaymentMethodGeneral
  checked?: boolean
  onClick?: () => any
  garantexCode?: string | null
}) => {
  return (
    <div
      className={cn({ [css.paymentMethodCard]: true, [css.checked]: checked, [css.clickable]: !!onClick })}
      onClick={onClick}
    >
      <LabeledValues
        valuesEmptyPolicy="hide"
        items={[
          ['Метод', toHumanPaymentMethodType(paymentMethod.type)],
          [
            'Номер карты',
            paymentMethod.group === 'card' && paymentMethod.cardNumber
              ? prettifyBankCard(paymentMethod.cardNumber)
              : null,
          ],
          ['Имя владельца', paymentMethod.group === 'card' ? paymentMethod.ownerName : null],
          [
            'Номер телефона',
            paymentMethod.group === 'sbp' && paymentMethod.ownerPhone ? prettifyPhone(paymentMethod.ownerPhone) : null,
          ],
          ['Имя владельца', paymentMethod.group === 'sbp' ? paymentMethod.ownerName : null],
          ['Номер кошелька', paymentMethod.group === 'crypto' ? paymentMethod.wallet : null],
          ['Код гаратекс', garantexCode],
        ]}
      />
    </div>
  )
}

const PaymentMethodEditorItem = ({
  formy,
  index,
  paymentMethod,
}: {
  formy: Formy
  index: number
  paymentMethod: PaymentMethodRecordUpsertDataInput
}) => {
  ;(paymentMethod as any).cardNumber = (paymentMethod as any).cardNumber || ''
  ;(paymentMethod as any).ownerName = (paymentMethod as any).ownerName || ''
  ;(paymentMethod as any).ownerPhone = (paymentMethod as any).ownerPhone || ''
  ;(paymentMethod as any).wallet = (paymentMethod as any).wallet || ''
  const paymentMethodGroupRef = useRef(paymentMethod.group)
  useEffect(() => {
    if (paymentMethod.group !== paymentMethodGroupRef.current) {
      void formy.setFieldValue(`paymentMethods.${index}.type`, paymentMethodsTypesByGroups[paymentMethod.group][0])
      paymentMethodGroupRef.current = paymentMethod.group
    }
  }, [paymentMethod.group])
  return (
    <Card style={{ width: 300 }}>
      <FormItems>
        {paymentMethod.group !== 'garantex' && (
          <Selecty
            formy={formy}
            name={`paymentMethods.${index}.group`}
            label="Группа"
            disabled={!!paymentMethod.id}
            options={paymentMethodsGroupsExternal.map((group) => ({
              value: group,
              label: toHumanPaymentMethodGroup(group),
            }))}
          />
        )}
        <Selecty
          formy={formy}
          name={`paymentMethods.${index}.type`}
          label="Метод"
          disabled={!!paymentMethod.id}
          options={paymentMethodsTypesByGroups[paymentMethod.group].map((method) => ({
            value: method,
            label: toHumanPaymentMethodType(method),
          }))}
        />
        {paymentMethod.group === 'card' && paymentMethod.type && (
          <>
            <Textfieldy
              disabled={!!paymentMethod.id}
              key="card-cardNumber"
              label="Номер карты"
              formy={formy}
              name={`paymentMethods.${index}.cardNumber`}
            />
            <Textfieldy
              disabled={!!paymentMethod.id}
              key="card-ownerName"
              label="Имя владельца"
              formy={formy}
              name={`paymentMethods.${index}.ownerName`}
            />
          </>
        )}
        {paymentMethod.group === 'sbp' && paymentMethod.type && (
          <>
            <Textfieldy
              disabled={!!paymentMethod.id}
              key="sbp-phone"
              label="Номер телефона"
              formy={formy}
              name={`paymentMethods.${index}.ownerPhone`}
            />
            <Textfieldy
              disabled={!!paymentMethod.id}
              key="sbp-ownerName"
              label="Имя владельца"
              formy={formy}
              name={`paymentMethods.${index}.ownerName`}
            />
          </>
        )}
        {paymentMethod.group === 'crypto' && paymentMethod.type && (
          <Textfieldy
            disabled={!!paymentMethod.id}
            key="crypto-wallet"
            label="Номер кошелька"
            formy={formy}
            name={`paymentMethods.${index}.wallet`}
          />
        )}
        <Switchy
          formy={formy}
          disabled={paymentMethod.group === 'garantex'}
          name={`paymentMethods.${index}.availableForInput`}
          label="Доступно для получения денег"
          optionLabel="Да"
        />
        <Switchy
          formy={formy}
          disabled={paymentMethod.group === 'garantex'}
          name={`paymentMethods.${index}.availableForOutput`}
          label="Доступно для вывода денег"
          optionLabel="Да"
        />
        {paymentMethod.group !== 'garantex' && (
          <Buttons>
            <Button
              type="button"
              onClick={() => {
                formy.removeArrayItem('paymentMethods', index)
              }}
            >
              Удалить
            </Button>
          </Buttons>
        )}
      </FormItems>
    </Card>
  )
}

export const PaymentMethodsEditor = ({
  updateMyPaymentMethodsTrpcRouteName,
  paymentMethods,
}: {
  updateMyPaymentMethodsTrpcRouteName:
    | 'updateMyPaymentMethodsForTrader'
    | 'updateCompanyPaymentMethodsForAdmin'
    | 'updateMerchantPaymentMethodsForMerchantMember'
  paymentMethods: ClientPaymentMethodForOwner[]
}) => {
  const updateMyPaymentMethods = trpc[updateMyPaymentMethodsTrpcRouteName].useMutation()
  const zSchema =
    updateMyPaymentMethodsTrpcRouteName === 'updateMyPaymentMethodsForTrader'
      ? zUpdateMyPaymentMethodsForTraderEndpointInput
      : updateMyPaymentMethodsTrpcRouteName === 'updateCompanyPaymentMethodsForAdmin'
        ? zUpdateCompanyPaymentMethodsForAdminEndpointInput
        : zUpdateMerchantPaymentMethodsForMerchantMemberEndpointInput
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      paymentMethods: paymentMethods as never,
    },
    validationSchema: zSchema,
    onSubmit: async ({ valuesInput }) => {
      await updateMyPaymentMethods.mutateAsync(valuesInput)
      if (updateMyPaymentMethodsTrpcRouteName === 'updateMyPaymentMethodsForTrader') {
        await trpcUtils.getMe.refetch()
      } else if (updateMyPaymentMethodsTrpcRouteName === 'updateCompanyPaymentMethodsForAdmin') {
        await trpcUtils.getCompany.refetch()
        await trpcUtils.getMe.refetch()
      } else {
        await trpcUtils.getMyMerchant.refetch()
        await trpcUtils.getMe.refetch()
      }
    },
    successMessage: 'Платёжные методы изменены',
    resetOnSuccess: false,
    enableReinitialize: true,
    showValidationErrorMessage: true,
  })
  return (
    <Segment title="Платёжные методы" size="s">
      <FormItems as="form" {...formy.formProps}>
        {formy.values.paymentMethods.map((paymentMethod, index) => (
          <PaymentMethodEditorItem key={index} formy={formy} index={index} paymentMethod={paymentMethod} />
        ))}
        <Block frw g={10}>
          <Buttons>
            <Button
              type="button"
              onClick={() => {
                formy.addArrayItem('paymentMethods', {
                  id: '',
                  group: 'crypto',
                  type: 'usdtTrc20',
                  wallet: '',
                  cardNumber: '',
                  ownerName: '',
                  availableForInput: true,
                  availableForOutput: true,
                } as PaymentMethodRecordUpsertDataInput)
              }}
            >
              Добавить метод
            </Button>
            <Button {...formy.buttonProps} type="submit">
              Сохранить изменения
            </Button>
          </Buttons>
        </Block>
      </FormItems>
    </Segment>
  )
}
