import '@/webapp/src/lib/sentry.js'
import '@/webapp/src/styles/global.scss'
import { NotFoundPageComponent } from '@/webapp/src/components/errors/NotFoundPageComponent/index.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { AnchorClickListener } from '@/webapp/src/lib/anchor.js'
import { AppContextPreloader } from '@/webapp/src/lib/ctx.js'
import * as routes from '@/webapp/src/lib/routes.js'
import { Toaster } from '@/webapp/src/lib/toaster.js'
import { TrpcProvider } from '@/webapp/src/lib/trpc.js'
import { ProgressLine } from '@/webapp/src/lib/uninty.components.js'
import { WindowSizeWatcher } from '@/webapp/src/lib/windowSize.js'
import { AdminResetPasswordPage } from '@/webapp/src/pages/admin/auth/AdminResetPasswordPage/index.js'
import { AdminRestorePasswordPage } from '@/webapp/src/pages/admin/auth/AdminRestorePasswordPage/index.js'
import { AdminSignInPage } from '@/webapp/src/pages/admin/auth/AdminSignInPage/index.js'
import { AdminSignOutPage } from '@/webapp/src/pages/admin/auth/AdminSignOutPage/index.js'
import { AdminActionLogListPage } from '@/webapp/src/pages/admin/other/AdminActionLogListPage/index.js'
import { AdminAdminEditPage } from '@/webapp/src/pages/admin/other/AdminAdminEditPage/index.js'
import { AdminAdminListPage } from '@/webapp/src/pages/admin/other/AdminAdminListPage/index.js'
import { AdminAdminNewPage } from '@/webapp/src/pages/admin/other/AdminAdminNewPage/index.js'
import { AdminAdminSettingsPage } from '@/webapp/src/pages/admin/other/AdminAdminSettingsPage/index.js'
import { AdminChatListPage } from '@/webapp/src/pages/admin/other/AdminChatListPage/index.js'
import { AdminCompanySettingsPage } from '@/webapp/src/pages/admin/other/AdminCompanySettingsPage/index.js'
import { AdminCustomerDepositListPage } from '@/webapp/src/pages/admin/other/AdminCustomerDepositListPage/index.js'
import { AdminCustomerDepositViewPage } from '@/webapp/src/pages/admin/other/AdminCustomerDepositViewPage/index.js'
import { AdminCustomerWithdrawListPage } from '@/webapp/src/pages/admin/other/AdminCustomerWithdrawListPage/index.js'
import { AdminCustomerWithdrawViewPage } from '@/webapp/src/pages/admin/other/AdminCustomerWithdrawViewPage/index.js'
import { AdminDashboardPage } from '@/webapp/src/pages/admin/other/AdminDashboardPage/index.js'
import { AdminMerchantDepositListPage } from '@/webapp/src/pages/admin/other/AdminMerchantDepositListPage/index.js'
import { AdminMerchantDepositViewPage } from '@/webapp/src/pages/admin/other/AdminMerchantDepositViewPage/index.js'
import { AdminMerchantEditPage } from '@/webapp/src/pages/admin/other/AdminMerchantEditPage/index.js'
import { AdminMerchantListPage } from '@/webapp/src/pages/admin/other/AdminMerchantListPage/index.js'
import { AdminMerchantMemberEditPage } from '@/webapp/src/pages/admin/other/AdminMerchantMemberEditPage/index.js'
import { AdminMerchantMemberListPage } from '@/webapp/src/pages/admin/other/AdminMerchantMemberListPage/index.js'
import { AdminMerchantMemberNewPage } from '@/webapp/src/pages/admin/other/AdminMerchantMemberNewPage/index.js'
import { AdminMerchantNewPage } from '@/webapp/src/pages/admin/other/AdminMerchantNewPage/index.js'
import { AdminMerchantWithdrawListPage } from '@/webapp/src/pages/admin/other/AdminMerchantWithdrawListPage/index.js'
import { AdminMerchantWithdrawViewPage } from '@/webapp/src/pages/admin/other/AdminMerchantWithdrawViewPage/index.js'
import { AdminTraderDepositListPage } from '@/webapp/src/pages/admin/other/AdminTraderDepositListPage/index.js'
import { AdminTraderDepositViewPage } from '@/webapp/src/pages/admin/other/AdminTraderDepositViewPage/index.js'
import { AdminTraderEditPage } from '@/webapp/src/pages/admin/other/AdminTraderEditPage/index.js'
import { AdminTraderInsuranceDepositListPage } from '@/webapp/src/pages/admin/other/AdminTraderInsuranceDepositListPage/index.js'
import { AdminTraderInsuranceDepositViewPage } from '@/webapp/src/pages/admin/other/AdminTraderInsuranceDepositViewPage/index.js'
import { AdminTraderInsuranceWithdrawListPage } from '@/webapp/src/pages/admin/other/AdminTraderInsuranceWithdrawListPage/index.js'
import { AdminTraderInsuranceWithdrawViewPage } from '@/webapp/src/pages/admin/other/AdminTraderInsuranceWithdrawViewPage/index.js'
import { AdminTraderListPage } from '@/webapp/src/pages/admin/other/AdminTraderListPage/index.js'
import { AdminTraderNewPage } from '@/webapp/src/pages/admin/other/AdminTraderNewPage/index.js'
import { AdminTraderWithdrawListPage } from '@/webapp/src/pages/admin/other/AdminTraderWithdrawListPage/index.js'
import { AdminTxListPage } from '@/webapp/src/pages/admin/other/AdminTxListPage/index.js'
import { ApiDocsPage } from '@/webapp/src/pages/docs/ApiDocsPage/index.js'
import { GeneralDocsPage } from '@/webapp/src/pages/docs/GeneralDocsPage/index.js'
import { PolicyPage } from '@/webapp/src/pages/docs/PolicyPage/index.js'
import { TermsPage } from '@/webapp/src/pages/docs/TermsPage/index.js'
import { WebhooksDocsPage } from '@/webapp/src/pages/docs/WebhooksDocsPage/index.js'
import { MerchantResetPasswordPage } from '@/webapp/src/pages/merchant/auth/MerchantResetPasswordPage/index.js'
import { MerchantRestorePasswordPage } from '@/webapp/src/pages/merchant/auth/MerchantRestorePasswordPage/index.js'
import { MerchantSignInPage } from '@/webapp/src/pages/merchant/auth/MerchantSignInPage/index.js'
import { MerchantSignOutPage } from '@/webapp/src/pages/merchant/auth/MerchantSignOutPage/index.js'
import { MerchantChatListPage } from '@/webapp/src/pages/merchant/other/MerchantChatListPage/index.js'
import { MerchantCustomerDepositListPage } from '@/webapp/src/pages/merchant/other/MerchantCustomerDepositListPage/index.js'
import { MerchantCustomerDepositViewPage } from '@/webapp/src/pages/merchant/other/MerchantCustomerDepositViewPage/index.js'
import { MerchantCustomerWithdrawListPage } from '@/webapp/src/pages/merchant/other/MerchantCustomerWithdrawListPage/index.js'
import { MerchantCustomerWithdrawViewPage } from '@/webapp/src/pages/merchant/other/MerchantCustomerWithdrawViewPage/index.js'
import { MerchantDashboardPage } from '@/webapp/src/pages/merchant/other/MerchantDashboardPage/index.js'
import { MerchantMerchantApiPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantApiPage/index.js'
import { MerchantMerchantDepositListPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantDepositListPage/index.js'
import { MerchantMerchantDepositNewPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantDepositNewPage/index.js'
import { MerchantMerchantDepositViewPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantDepositViewPage/index.js'
import { MerchantMerchantMemberListPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantMemberListPage/index.js'
import { MerchantMerchantSettingsPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantSettingsPage/index.js'
import { MerchantMerchantWebhooksPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantWebhooksPage/index.js'
import { MerchantMerchantWithdrawListPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantWithdrawListPage/index.js'
import { MerchantMerchantWithdrawNewPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantWithdrawNewPage/index.js'
import { MerchantMerchantWithdrawViewPage } from '@/webapp/src/pages/merchant/other/MerchantMerchantWithdrawViewPage/index.js'
import { MerchantMerchatMemberSettingsPage } from '@/webapp/src/pages/merchant/other/MerchantMerchatMemberSettingsPage/index.js'
import { MerchantTxListPage } from '@/webapp/src/pages/merchant/other/MerchantTxListPage/index.js'
import { MerchantCustomerCustomerDepositViewPage } from '@/webapp/src/pages/merchantCustomer/MerchantCustomerCustomerDepositViewPage/index.js'
import { MerchantCustomerCustomerWithdrawViewPage } from '@/webapp/src/pages/merchantCustomer/MerchantCustomerCustomerWithdrawViewPage/index.js'
import { HomePage } from '@/webapp/src/pages/other/HomePage/index.js'
import { TraderResetPasswordPage } from '@/webapp/src/pages/trader/auth/TraderResetPasswordPage/index.js'
import { TraderRestorePasswordPage } from '@/webapp/src/pages/trader/auth/TraderRestorePasswordPage/index.js'
import { TraderSignInPage } from '@/webapp/src/pages/trader/auth/TraderSignInPage/index.js'
import { TraderSignOutPage } from '@/webapp/src/pages/trader/auth/TraderSignOutPage/index.js'
import { TraderAutomatorPage } from '@/webapp/src/pages/trader/other/TraderAutomatorPage/index.js'
import { TraderChatListPage } from '@/webapp/src/pages/trader/other/TraderChatListPage/index.js'
import { TraderCustomerDepositListPage } from '@/webapp/src/pages/trader/other/TraderCustomerDepositListPage/index.js'
import { TraderCustomerDepositViewPage } from '@/webapp/src/pages/trader/other/TraderCustomerDepositViewPage/index.js'
import { TraderCustomerWithdrawListPage } from '@/webapp/src/pages/trader/other/TraderCustomerWithdrawListPage/index.js'
import { TraderCustomerWithdrawViewPage } from '@/webapp/src/pages/trader/other/TraderCustomerWithdrawViewPage/index.js'
import { TraderDashboardPage } from '@/webapp/src/pages/trader/other/TraderDashboardPage/index.js'
import { TraderSettingsPage } from '@/webapp/src/pages/trader/other/TraderSettingsPage/index.js'
import { TraderTraderDepositListPage } from '@/webapp/src/pages/trader/other/TraderTraderDepositListPage/index.js'
import { TraderTraderDepositNewPage } from '@/webapp/src/pages/trader/other/TraderTraderDepositNewPage/index.js'
import { TraderTraderDepositViewPage } from '@/webapp/src/pages/trader/other/TraderTraderDepositViewPage/index.js'
import { TraderTraderInsuranceDepositListPage } from '@/webapp/src/pages/trader/other/TraderTraderInsuranceDepositListPage/index.js'
import { TraderTraderInsuranceDepositNewPage } from '@/webapp/src/pages/trader/other/TraderTraderInsuranceDepositNewPage/index.js'
import { TraderTraderInsuranceDepositViewPage } from '@/webapp/src/pages/trader/other/TraderTraderInsuranceDepositViewPage/index.js'
import { TraderTraderInsuranceWithdrawListPage } from '@/webapp/src/pages/trader/other/TraderTraderInsuranceWithdrawListPage/index.js'
import { TraderTraderInsuranceWithdrawNewPage } from '@/webapp/src/pages/trader/other/TraderTraderInsuranceWithdrawNewPage/index.js'
import { TraderTraderInsuranceWithdrawViewPage } from '@/webapp/src/pages/trader/other/TraderTraderInsuranceWithdrawViewPage/index.js'
import { TraderTraderWithdrawListPage } from '@/webapp/src/pages/trader/other/TraderTraderWithdrawListPage/index.js'
import { TraderTxListPage } from '@/webapp/src/pages/trader/other/TraderTxListPage/index.js'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
;(BigInt.prototype as any).toJSON = function () {
  return this.toString()
}

export const App = () => {
  return (
    <HelmetProvider>
      <TrpcProvider>
        <AppContextPreloader>
          <BrowserRouter>
            <AnchorClickListener />
            <ProgressLine />
            <WindowSizeWatcher />
            <Toaster />
            <Routes>
              <Route
                path={routes.merchantCustomerCustomerWithdrawViewRoute.definition}
                element={<MerchantCustomerCustomerWithdrawViewPage />}
              />
              <Route
                path={routes.merchantCustomerCustomerDepositViewRoute.definition}
                element={<MerchantCustomerCustomerDepositViewPage />}
              />
              <Route path={routes.traderSignInRoute.definition} element={<TraderSignInPage />} />
              <Route path={routes.traderRestorePasswordRoute.definition} element={<TraderRestorePasswordPage />} />
              <Route path={routes.traderResetPasswordRoute.definition} element={<TraderResetPasswordPage />} />
              <Route path={routes.traderSignOutRoute.definition} element={<TraderSignOutPage />} />
              <Route
                path={routes.traderCustomerDepositViewRoute.definition}
                element={<TraderCustomerDepositViewPage />}
              />
              <Route path={routes.traderTraderDepositViewRoute.definition} element={<TraderTraderDepositViewPage />} />
              <Route
                path={routes.traderTraderInsuranceDepositViewRoute.definition}
                element={<TraderTraderInsuranceDepositViewPage />}
              />
              <Route path={routes.traderTxsRoute.definition} element={<TraderTxListPage />} />
              <Route path={routes.traderChatsRoute.definition} element={<TraderChatListPage />} />
              <Route path={routes.traderAutomatorRoute.definition} element={<TraderAutomatorPage />} />
              <Route path={routes.traderTraderDepositNewRoute.definition} element={<TraderTraderDepositNewPage />} />
              <Route
                path={routes.traderCustomerWithdrawViewRoute.definition}
                element={<TraderCustomerWithdrawViewPage />}
              />
              <Route
                path={routes.traderTraderInsuranceWithdrawListRoute.definition}
                element={<TraderTraderInsuranceWithdrawListPage />}
              />
              <Route path={routes.traderSettingsRoute.definition} element={<TraderSettingsPage />} />
              <Route
                path={routes.traderTraderInsuranceWithdrawViewRoute.definition}
                element={<TraderTraderInsuranceWithdrawViewPage />}
              />
              <Route
                path={routes.traderCustomerWithdrawListRoute.definition}
                element={<TraderCustomerWithdrawListPage />}
              />
              <Route path={routes.traderDashboardRoute.definition} element={<TraderDashboardPage />} />
              <Route
                path={routes.traderTraderWithdrawListRoute.definition}
                element={<TraderTraderWithdrawListPage />}
              />
              <Route path={routes.traderTraderDepositListRoute.definition} element={<TraderTraderDepositListPage />} />
              <Route
                path={routes.traderTraderInsuranceDepositNewRoute.definition}
                element={<TraderTraderInsuranceDepositNewPage />}
              />
              <Route
                path={routes.traderTraderInsuranceWithdrawNewRoute.definition}
                element={<TraderTraderInsuranceWithdrawNewPage />}
              />
              <Route
                path={routes.traderCustomerDepositListRoute.definition}
                element={<TraderCustomerDepositListPage />}
              />
              <Route
                path={routes.traderTraderInsuranceDepositListRoute.definition}
                element={<TraderTraderInsuranceDepositListPage />}
              />
              <Route path={routes.homeRoute.definition} element={<HomePage />} />
              <Route path={routes.adminSignOutRoute.definition} element={<AdminSignOutPage />} />
              <Route path={routes.adminResetPasswordRoute.definition} element={<AdminResetPasswordPage />} />
              <Route path={routes.adminSignInRoute.definition} element={<AdminSignInPage />} />
              <Route path={routes.adminRestorePasswordRoute.definition} element={<AdminRestorePasswordPage />} />
              <Route path={routes.adminTraderEditRoute.definition} element={<AdminTraderEditPage />} />
              <Route path={routes.adminSettingsRoute.definition} element={<AdminAdminSettingsPage />} />
              <Route path={routes.adminMerchantMemberEditRoute.definition} element={<AdminMerchantMemberEditPage />} />
              <Route
                path={routes.adminTraderInsuranceDepositViewRoute.definition}
                element={<AdminTraderInsuranceDepositViewPage />}
              />
              <Route path={routes.adminTraderDepositViewRoute.definition} element={<AdminTraderDepositViewPage />} />
              <Route
                path={routes.adminTraderInsuranceWithdrawListRoute.definition}
                element={<AdminTraderInsuranceWithdrawListPage />}
              />
              <Route path={routes.adminMerchantNewRoute.definition} element={<AdminMerchantNewPage />} />
              <Route path={routes.adminAdminListRoute.definition} element={<AdminAdminListPage />} />
              <Route
                path={routes.adminMerchantWithdrawListRoute.definition}
                element={<AdminMerchantWithdrawListPage />}
              />
              <Route path={routes.adminMerchantListRoute.definition} element={<AdminMerchantListPage />} />
              <Route
                path={routes.adminCustomerDepositViewRoute.definition}
                element={<AdminCustomerDepositViewPage />}
              />
              <Route
                path={routes.adminMerchantDepositViewRoute.definition}
                element={<AdminMerchantDepositViewPage />}
              />
              <Route path={routes.adminAdminNewRoute.definition} element={<AdminAdminNewPage />} />
              <Route
                path={routes.adminCustomerWithdrawViewRoute.definition}
                element={<AdminCustomerWithdrawViewPage />}
              />
              <Route path={routes.adminChatsRoute.definition} element={<AdminChatListPage />} />
              <Route path={routes.adminAdminEditRoute.definition} element={<AdminAdminEditPage />} />
              <Route
                path={routes.adminMerchantDepositListRoute.definition}
                element={<AdminMerchantDepositListPage />}
              />
              <Route
                path={routes.adminMerchantWithdrawViewRoute.definition}
                element={<AdminMerchantWithdrawViewPage />}
              />
              <Route path={routes.adminActionLogsRoute.definition} element={<AdminActionLogListPage />} />
              <Route path={routes.adminDashboardRoute.definition} element={<AdminDashboardPage />} />
              <Route
                path={routes.adminCustomerDepositListRoute.definition}
                element={<AdminCustomerDepositListPage />}
              />
              <Route path={routes.adminMerchantMemberListRoute.definition} element={<AdminMerchantMemberListPage />} />
              <Route path={routes.adminTxsRoute.definition} element={<AdminTxListPage />} />
              <Route
                path={routes.adminCustomerWithdrawListRoute.definition}
                element={<AdminCustomerWithdrawListPage />}
              />
              <Route path={routes.adminTraderListRoute.definition} element={<AdminTraderListPage />} />
              <Route
                path={routes.adminTraderInsuranceDepositListRoute.definition}
                element={<AdminTraderInsuranceDepositListPage />}
              />
              <Route
                path={routes.adminTraderInsuranceWithdrawViewRoute.definition}
                element={<AdminTraderInsuranceWithdrawViewPage />}
              />
              <Route path={routes.adminTraderDepositListRoute.definition} element={<AdminTraderDepositListPage />} />
              <Route path={routes.adminMerchantMemberNewRoute.definition} element={<AdminMerchantMemberNewPage />} />
              <Route path={routes.adminCompanySettingsRoute.definition} element={<AdminCompanySettingsPage />} />
              <Route path={routes.adminTraderWithdrawListRoute.definition} element={<AdminTraderWithdrawListPage />} />
              <Route path={routes.adminTraderNewRoute.definition} element={<AdminTraderNewPage />} />
              <Route path={routes.adminMerchantEditRoute.definition} element={<AdminMerchantEditPage />} />
              <Route path={routes.webhooksDocsRoute.definition} element={<WebhooksDocsPage />} />
              <Route path={routes.termsRoute.definition} element={<TermsPage />} />
              <Route path={routes.policyRoute.definition} element={<PolicyPage />} />
              <Route path={routes.apiDocsRoute.definition} element={<ApiDocsPage />} />
              <Route path={routes.generalDocsRoute.definition} element={<GeneralDocsPage />} />
              <Route path={routes.merchantResetPasswordRoute.definition} element={<MerchantResetPasswordPage />} />
              <Route path={routes.merchantSignOutRoute.definition} element={<MerchantSignOutPage />} />
              <Route path={routes.merchantSignInRoute.definition} element={<MerchantSignInPage />} />
              <Route path={routes.merchantRestorePasswordRoute.definition} element={<MerchantRestorePasswordPage />} />
              <Route
                path={routes.merchantCustomerWithdrawListRoute.definition}
                element={<MerchantCustomerWithdrawListPage />}
              />
              <Route
                path={routes.merchantMerchantDepositViewRoute.definition}
                element={<MerchantMerchantDepositViewPage />}
              />
              <Route
                path={routes.merchantCustomerDepositViewRoute.definition}
                element={<MerchantCustomerDepositViewPage />}
              />
              <Route
                path={routes.merchantMerchantWithdrawViewRoute.definition}
                element={<MerchantMerchantWithdrawViewPage />}
              />
              <Route path={routes.merchantDashboardRoute.definition} element={<MerchantDashboardPage />} />
              <Route path={routes.merchantApiRoute.definition} element={<MerchantMerchantApiPage />} />
              <Route
                path={routes.merchantMerchantMemberListRoute.definition}
                element={<MerchantMerchantMemberListPage />}
              />
              <Route path={routes.merchantChatsRoute.definition} element={<MerchantChatListPage />} />
              <Route path={routes.merchantTxsRoute.definition} element={<MerchantTxListPage />} />
              <Route
                path={routes.merchantMerchantDepositNewRoute.definition}
                element={<MerchantMerchantDepositNewPage />}
              />
              <Route
                path={routes.merchantMemberSettingsRoute.definition}
                element={<MerchantMerchatMemberSettingsPage />}
              />
              <Route path={routes.merchantWebhooksRoute.definition} element={<MerchantMerchantWebhooksPage />} />
              <Route path={routes.merchantSettingsRoute.definition} element={<MerchantMerchantSettingsPage />} />
              <Route
                path={routes.merchantCustomerDepositListRoute.definition}
                element={<MerchantCustomerDepositListPage />}
              />
              <Route
                path={routes.merchantMerchantDepositListRoute.definition}
                element={<MerchantMerchantDepositListPage />}
              />
              <Route
                path={routes.merchantCustomerWithdrawViewRoute.definition}
                element={<MerchantCustomerWithdrawViewPage />}
              />
              <Route
                path={routes.merchantMerchantWithdrawListRoute.definition}
                element={<MerchantMerchantWithdrawListPage />}
              />
              <Route
                path={routes.merchantMerchantWithdrawNewRoute.definition}
                element={<MerchantMerchantWithdrawNewPage />}
              />
              <Route
                path="*"
                element={
                  <GeneralLayout>
                    <NotFoundPageComponent />
                  </GeneralLayout>
                }
              />
            </Routes>
          </BrowserRouter>
        </AppContextPreloader>
      </TrpcProvider>
    </HelmetProvider>
  )
}
