import { pageData } from './pageData.js'
import { zCreateMerchantMemberForAdminEndpointInput } from '@/general/src/merchantMember/routes/createMerchantMemberForAdmin/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { getOneEnv } from '@/webapp/src/lib/env.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminMerchantMemberEditRoute, adminMerchantMemberNewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const AdminMerchantMemberNewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(adminMerchantMemberNewRoute)
    return trpc.getMerchantForAdmin.useQuery({ merchantSerialNumber: +routeParams.merchantSerialNumber })
  },
  setProps: ({ queryResult }) => ({
    merchant: queryResult.data.merchant,
  }),
})(({ merchant }) => {
  const isLocal = getOneEnv('HOST_ENV') === 'local'
  const navigate = useNavigate()
  const [newPassword, setNewPassword] = useState('')
  const [newMerchantMemberSerialNumber, setNewMerchantMemberSerialNumber] = useState<number>()

  const createMerchantMemberForAdmin = trpc.createMerchantMemberForAdmin.useMutation()
  const formy = useFormy({
    initialValues: {
      merchantId: merchant.id,
      name: '',
      phone: '',
      email: '',
      telegram: '',
    },
    validationSchema: zCreateMerchantMemberForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await createMerchantMemberForAdmin.mutateAsync({
        ...valuesInput,
      })

      setNewPassword(res.merchantMember.password)
      setNewMerchantMemberSerialNumber(res.merchantMember.serialNumber)

      // if (!isLocal) {
      // navigate(
      //   adminMerchantMemberEditRoute.get({
      //     merchantMemberSerialNumber: res.merchantMember.serialNumber,
      //     merchantSerialNumber: merchant.serialNumber,
      //   })
      // )
      // }
    },
    successMessage: 'Сотрудник мерчанта создан',
    resetOnSuccess: true,
  })
  return (
    <Block fcnw>
      <Segment title={`Новый сотрудник мерчанта #${merchant.serialNumber} "${merchant.name}"`} size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Почта" {...formy.getFieldProps('email')} />
          <Textfieldy label="Телефон" {...formy.getFieldProps('phone')} />
          <Textfieldy label="Телеграм" {...formy.getFieldProps('telegram')} />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Создать
            </Button>
          </Buttons>

          {newPassword && newMerchantMemberSerialNumber && (
            <>
              <p>
                Пароль нового сотрудника мерчанта: <input type="text" value={newPassword} contentEditable={false} />
              </p>
              <p>
                Передайте пароль сотруднику мерчанта, которого вы добавили. Для безопасности, нужно, чтобы он его сменил
                на свой.
              </p>

              <p>
                <a
                  href={adminMerchantMemberEditRoute.get({
                    merchantMemberSerialNumber: newMerchantMemberSerialNumber,
                    merchantSerialNumber: merchant.serialNumber,
                  })}
                >
                  Перейти на страницу настроек нового сотрудника мерчанта
                </a>
              </p>
            </>
          )}
        </FormItems>
      </Segment>
    </Block>
  )
})
