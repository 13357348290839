import { pageData } from './pageData.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { Block, Segment } from '@/webapp/src/lib/uninty.components.js'

export const AdminTraderWithdrawListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  return (
    <Block fcnw>
      <Segment title="Все трейдерские виздравы" size="m">
        <p>
          На данный момент чтобы трейдеру вывести свои деньги с платформы, ему нужно участвовать в клиентских депозитах
        </p>
      </Segment>
    </Block>
  )
})
