import { pageData } from './pageData.js'
import { zCreateMerchantForAdminEndpointInput } from '@/general/src/merchant/routes/createMerchantForAdmin/input.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminMerchantEditRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useNavigate } from 'react-router-dom'

export const AdminMerchantNewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  const navigate = useNavigate()
  const createMerchantForAdmin = trpc.createMerchantForAdmin.useMutation()
  const formy = useFormy({
    initialValues: {
      name: '',
      customerDepositFeeShare: '',
      customerWithdrawFeeShare: '',
    },
    validationSchema: zCreateMerchantForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const res = await createMerchantForAdmin.mutateAsync({
        ...valuesInput,
      })
      navigate(adminMerchantEditRoute.get({ merchantSerialNumber: res.merchant.serialNumber }))
    },
    successMessage: 'Мерчант создан',
  })
  return (
    <Block fcnw>
      <Segment title="Новый мерчант" size="m">
        <FormItems as="form" {...formy.formProps}>
          <Textfieldy label="Имя" {...formy.getFieldProps('name')} />
          <Textfieldy label="Процент от клиентского депозита" {...formy.getFieldProps('customerDepositFeeShare')} />
          <Textfieldy label="Процент от клиентского виздрава" {...formy.getFieldProps('customerWithdrawFeeShare')} />
          <Buttons>
            <Button {...formy.buttonProps} type="submit">
              Создать
            </Button>
          </Buttons>
        </FormItems>
      </Segment>
    </Block>
  )
})
