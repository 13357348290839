import { toHumanCustomerDepositStatus } from '@/general/src/customerDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { traderCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const ActiveCustomerDeposits = withSectionWrapper({
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getCustomerDepositsForTrader.useQuery(
      {
        myActive: true,
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult }) => ({
    customerDeposits: queryResult.data.customerDeposits,
  }),
})(({ customerDeposits }) => {
  return !customerDeposits.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      records={customerDeposits}
      href={(record) => traderCustomerDepositViewRoute.get({ customerDepositSerialNumber: record.serialNumber })}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        { heading: 'Статус', body: (record) => toHumanCustomerDepositStatus(record.status), width: 150 },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Метод',
          body: (record) => toHumanPaymentMethodType(record.paymentMethodType),
          width: 200,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.dealAmount, currency: record.dealCurrency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
      ]}
    />
  )
})
