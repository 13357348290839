import { pageData } from './pageData.js'
import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { zCancelPickingMerchantWithdrawForMerchantMemberEndpointInput } from '@/general/src/merchantWithdraw/routes/cancelPickingMerchantWithdrawForMerchantMember/input.js'
import { zDisputeStartMerchantWithdrawForMerchantMemberEndpointInput } from '@/general/src/merchantWithdraw/routes/disputeStartMerchantWithdrawForMerchantMember/input.js'
import {
  toHumanMerchantWithdrawCancellationReason,
  toHumanMerchantWithdrawStatus,
} from '@/general/src/merchantWithdraw/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { Textfieldy, useFormy } from '@/webapp/src/lib/formy.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { merchantMerchantWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, FormItems, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { withMessage } from '@/webapp/src/lib/utils.js'
import { differenceInMinutes } from 'date-fns'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'

const CancelPicking = ({
  merchantWithdraw,
}: {
  merchantWithdraw: TrpcRouterOutput['getMerchantWithdrawForMerchantMember']['merchantWithdraw']
}) => {
  const cancelPickingMerchantWithdrawForMerchantMember =
    trpc.cancelPickingMerchantWithdrawForMerchantMember.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantWithdrawId: merchantWithdraw.id,
      message: '',
    },
    validationSchema: zCancelPickingMerchantWithdrawForMerchantMemberEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantWithdraw } = await cancelPickingMerchantWithdrawForMerchantMember.mutateAsync(valuesInput)
      trpcUtils.getMerchantWithdrawForMerchantMember.setData(
        { merchantWithdrawSerialNumber: merchantWithdraw.serialNumber },
        { merchantWithdraw }
      )
    },
    successMessage: 'Мерчантский виздрав успешно отменён',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина отмены" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Отменить
        </Button>
      </Buttons>
    </FormItems>
  )
}

const DispiteStart = ({
  merchantWithdraw,
}: {
  merchantWithdraw: TrpcRouterOutput['getMerchantWithdrawForMerchantMember']['merchantWithdraw']
}) => {
  const disputeStartMerchantWithdrawForMerchantMember = trpc.disputeStartMerchantWithdrawForMerchantMember.useMutation()
  const trpcUtils = trpc.useUtils()
  const formy = useFormy({
    initialValues: {
      merchantWithdrawId: merchantWithdraw.id,
      message: '',
    },
    validationSchema: zDisputeStartMerchantWithdrawForMerchantMemberEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantWithdraw } = await disputeStartMerchantWithdrawForMerchantMember.mutateAsync(valuesInput)
      trpcUtils.getMerchantWithdrawForMerchantMember.setData(
        { merchantWithdrawSerialNumber: merchantWithdraw.serialNumber },
        { merchantWithdraw }
      )
    },
    successMessage: 'Спор по мерчантскому виздраву начат',
    resetOnSuccess: false,
  })
  return (
    <FormItems as="form" {...formy.formProps}>
      <Textfieldy {...formy.getFieldProps('message')} label="Причина" />
      <Buttons>
        <Button {...formy.buttonProps} type="submit">
          Начать спор
        </Button>
      </Buttons>
    </FormItems>
  )
}

export const MerchantMerchantWithdrawViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(merchantMerchantWithdrawViewRoute)
    const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
    const queryResult = trpc.getMerchantWithdrawForMerchantMember.useQuery(
      {
        merchantWithdrawSerialNumber: +routeParams.merchantWithdrawSerialNumber,
      },
      {
        refetchInterval,
      }
    )
    const isActive = queryResult.data?.merchantWithdraw.isActive
    useEffect(() => {
      if (isActive) {
        setRefetchInterval(3_000)
      } else {
        setRefetchInterval(false)
      }
    }, [isActive])
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    merchantWithdraw: queryResult.data.merchantWithdraw,
  }),
})(({ merchantWithdraw }) => {
  return (
    <Segment title={`Мерчантский виздрав #${merchantWithdraw.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanMerchantWithdrawStatus(merchantWithdraw.status)],
            ['Сумма в USDT', toMoney({ amount: merchantWithdraw.amountUsdt, currency: 'usdt' })],
            [
              'Платёжный метод',
              merchantWithdraw.paymentMethodType && toHumanPaymentMethodType(merchantWithdraw.paymentMethodType),
            ],
            [
              'Номер кошелька',
              merchantWithdraw.merchantPaymentMethodData?.group === 'crypto'
                ? merchantWithdraw.merchantPaymentMethodData.wallet
                : null,
            ],
            ['Код гарантекс', merchantWithdraw.garantexCode],
            ['Админ', merchantWithdraw.admin?.serialNumber && `#${merchantWithdraw.admin.serialNumber}`],
            ['Мерчант мембер', `#${merchantWithdraw.merchantMember.serialNumber}`],
            ['Создание', formatDate(merchantWithdraw.createdAt, 'dd.MM.yyyy HH:mm')],
            ['Взят в работу', merchantWithdraw.pickedAt && formatDate(merchantWithdraw.pickedAt, 'dd.MM.yyyy HH:mm')],
            ['Оплачено', merchantWithdraw.completedAt && formatDate(merchantWithdraw.completedAt, 'dd.MM.yyyy HH:mm')],
            ['Отменено', merchantWithdraw.cancelledAt && formatDate(merchantWithdraw.cancelledAt, 'dd.MM.yyyy HH:mm')],
            [
              'Начало спора',
              merchantWithdraw.disputeStartedAt && formatDate(merchantWithdraw.disputeStartedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Завершение спора',
              merchantWithdraw.disputeCompletedAt &&
                formatDate(merchantWithdraw.disputeCompletedAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Отмена спора',
              merchantWithdraw.disputeCancelledAt &&
                formatDate(merchantWithdraw.disputeCancelledAt, 'dd.MM.yyyy HH:mm'),
            ],
            [
              'Длительность в минутах',
              merchantWithdraw.finishedAt &&
                differenceInMinutes(merchantWithdraw.finishedAt, merchantWithdraw.createdAt),
            ],
          ]}
        />
        {merchantWithdraw.status === 'picking' ? (
          <>
            <Segment title="Ваша задача" size="m" desc="Дождаться, пока админ возьмётся за ваш виздрав" />
            <Segment title="Отмена сделки" size="m" desc="Вы можете отменить сделку на этом этапе">
              <CancelPicking merchantWithdraw={merchantWithdraw} />
            </Segment>
          </>
        ) : merchantWithdraw.status === 'processing' ? (
          <Segment
            title="Ваша задача"
            size="m"
            desc="Дождаться, пока админ сделает перевод средств по указанным реквизитам"
          />
        ) : merchantWithdraw.status === 'completed' ? (
          <>
            <Segment
              title="Сделка успешно завершена"
              size="m"
              desc="Деньги были переведны по указанным реквизитам в ближайшие минуты уже должны быть у вас"
            />
            {merchantWithdraw.isDisputable && (
              <Segment
                title="Деньги не приходят?"
                size="m"
                desc="Если деньги долго не приходят, вы можете начать спор, чтобы пришёл админ и разобрался"
              >
                <DispiteStart merchantWithdraw={merchantWithdraw} />
              </Segment>
            )}
          </>
        ) : merchantWithdraw.status === 'cancelled' ? (
          <Segment
            title="Сделка была отменена"
            size="m"
            desc={toHumanMerchantWithdrawCancellationReason(
              merchantWithdraw.cancellationCode,
              merchantWithdraw.cancellationMessage
            )}
          />
        ) : merchantWithdraw.status === 'disputing' ? (
          <Segment
            title="Идёт спор"
            size="m"
            desc={withMessage(`Администрация разбирается в споре`, merchantWithdraw.disputeStartMessage)}
          />
        ) : merchantWithdraw.status === 'refunded' ? (
          <Segment title="Средства по сделке были возвращены" size="m" />
        ) : null}
        <Chat chatId={merchantWithdraw.chatId} viewerType="merchantMember" />
      </Block>
    </Segment>
  )
})
