import { useAppContext } from '@/webapp/src/lib/ctx.js'
import { createStorik } from '@/webapp/src/lib/storik.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { beep } from '@/webapp/src/lib/utils.js'
import { useEffect, useState } from 'react'

export const unresolvedChatsCounterStorik = createStorik({
  defaultValue: {
    admin: {
      chatsIds: [] as string[],
      chatsCount: 0,
    },
    trader: {
      chatsIds: [] as string[],
      chatsCount: 0,
    },
    merchantMember: {
      chatsIds: [] as string[],
      chatsCount: 0,
    },
  },
})

export const UnresolvedChatsCounterSpecific = ({
  viewerType,
}: {
  viewerType: 'trader' | 'admin' | 'merchantMember'
}) => {
  const [initial, setInitial] = useState(true)
  const store = unresolvedChatsCounterStorik.useStore()
  const oldChatsIds = store[viewerType].chatsIds
  const usualQuery =
    viewerType === 'trader'
      ? trpc.getUnresolvedChatsIdsForTrader
      : viewerType === 'merchantMember'
        ? trpc.getUnresolvedChatsIdsForMerchantMember
        : viewerType === 'admin'
          ? trpc.getUnresolvedChatsIdsForAdmin
          : trpc.getUnresolvedChatsIdsForAdmin
  const { data } = usualQuery.useQuery(
    {},
    {
      refetchInterval: 3_000,
    }
  )
  useEffect(() => {
    if (data) {
      const newChatsIds = data.chatsIds
      const chatsIdsAdded = !!newChatsIds.filter((id) => !oldChatsIds.includes(id)).length
      if (chatsIdsAdded && !initial) {
        beep()
      }
      unresolvedChatsCounterStorik.updateStore({
        [viewerType]: {
          chatsIds: newChatsIds,
          chatsCount: newChatsIds.length,
        },
      })
      setInitial(false)
    }
  }, [data?.chatsIds?.join(','), oldChatsIds.join(','), initial])
  return null
}

export const useUnresolvedChatsCounterActiualizer = () => {
  const trpcUtils = trpc.useUtils()
  return {
    actualizeUnresolvedChatsCounter: async () => {
      await Promise.all([
        trpcUtils.getUnresolvedChatsIdsForTrader.refetch({}),
        trpcUtils.getUnresolvedChatsIdsForMerchantMember.refetch({}),
        trpcUtils.getUnresolvedChatsIdsForAdmin.refetch({}),
      ])
    },
  }
}

export const useUnresolvedChatsCounter = () => {
  return unresolvedChatsCounterStorik.useStore()
}

export const UnresolvedChatsCounter = () => {
  const { me } = useAppContext()
  return (
    <>
      {me.trader && <UnresolvedChatsCounterSpecific viewerType="trader" />}
      {me.admin && <UnresolvedChatsCounterSpecific viewerType="admin" />}
      {me.merchantMember && <UnresolvedChatsCounterSpecific viewerType="merchantMember" />}
    </>
  )
}
