import type {
  MerchantDepositCancellationCode,
  MerchantDepositStatus as PrismaMerchantDepositStatus,
} from '@/backend/src/services/other/prisma.js'
import type { CheckIfArraySatisfies } from 'svag-types'
import { z } from 'zod'

export const merchantDepositStatuses = [
  'picking',
  'processing',
  'verifying',
  'completed',
  'cancelled',
  'disputing',
] as const satisfies PrismaMerchantDepositStatus[]
export const zMerchantDepositStatus = z.enum(merchantDepositStatuses)
export type MerchantDepositStatus = (typeof merchantDepositStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: CheckIfArraySatisfies<MerchantDepositStatus, PrismaMerchantDepositStatus> = true

export const toHumanMerchantDepositStatus = (status: MerchantDepositStatus) => {
  return {
    picking: 'Поиск исполнителя',
    processing: 'Ожидание перевода',
    verifying: 'Проверка перевода',
    completed: 'Завершён',
    cancelled: 'Отменён',
    disputing: 'Спор',
  }[status]
}

export const toHumanMerchantDepositCancellationCode = (code: MerchantDepositCancellationCode | null) => {
  if (!code) {
    return 'Сделка не отменена'
  }
  return {
    picking_merchantMember_manual: 'Сотрудник мерчанта отказался от сделки на этапе подбора исполнителя',
    processing_merchantMember_manual: 'Сотрудник мерчанта отказался от сделки на этапе перевода денег',
    disputing_admin_disputeCompletion: 'Спор завершён администратором в пользу платформы',
  }[code]
}

export const toHumanMerchantDepositCancellationReason = (
  code: MerchantDepositCancellationCode | null,
  message: string | null
) => {
  const humanCode = toHumanMerchantDepositCancellationCode(code)
  if (!message) {
    return humanCode
  }
  return `${humanCode}: ${message}`
}
