import type {
  MerchantWithdrawCancellationCode,
  MerchantWithdrawStatus as PrismaMerchantWithdrawStatus,
} from '@/backend/src/services/other/prisma.js'
import type { CheckIfArraySatisfies } from 'svag-types'
import { z } from 'zod'

export const merchantWithdrawStatuses = [
  'picking',
  'processing',
  'completed',
  'cancelled',
  'disputing',
  'refunded',
] as const satisfies PrismaMerchantWithdrawStatus[]
export const zMerchantWithdrawStatus = z.enum(merchantWithdrawStatuses)
export type MerchantWithdrawStatus = (typeof merchantWithdrawStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: CheckIfArraySatisfies<MerchantWithdrawStatus, PrismaMerchantWithdrawStatus> = true

export const toHumanMerchantWithdrawStatus = (status: MerchantWithdrawStatus) => {
  return {
    picking: 'Поиск исполнителя',
    processing: 'Ожидание перевода',
    completed: 'Завершён',
    cancelled: 'Отменён',
    disputing: 'Спор',
    refunded: 'Возвращён',
  }[status]
}

export const toHumanMerchantWithdrawCancellationCode = (code: MerchantWithdrawCancellationCode | null) => {
  if (!code) {
    return 'Сделка не отменена'
  }
  return {
    picking_merchantMember_manual: 'Сотрудник мерчанта отказался от сделки на этапе подбора исполнителя',
    picking_admin_manual: 'Администратор отменил сделку на этапе подбора исполнителя',
    processing_admin_manual: 'Администратор отменил сделку на этапе перевода денег',
  }[code]
}

export const toHumanMerchantWithdrawCancellationReason = (
  code: MerchantWithdrawCancellationCode | null,
  message: string | null
) => {
  const humanCode = toHumanMerchantWithdrawCancellationCode(code)
  if (!message) {
    return humanCode
  }
  return `${humanCode}: ${message}`
}
