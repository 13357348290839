import type {
  TraderDepositCancellationCode,
  TraderDepositStatus as PrismaTraderDepositStatus,
} from '@/backend/src/services/other/prisma.js'
import type { CheckIfArraySatisfies } from 'svag-types'
import { z } from 'zod'

export const traderDepositStatuses = [
  'picking',
  'processing',
  'verifying',
  'completed',
  'cancelled',
  'disputing',
] as const satisfies PrismaTraderDepositStatus[]
export const zTraderDepositStatus = z.enum(traderDepositStatuses)
export type TraderDepositStatus = (typeof traderDepositStatuses)[number]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check1: CheckIfArraySatisfies<TraderDepositStatus, PrismaTraderDepositStatus> = true

export const toHumanTraderDepositStatus = (status: TraderDepositStatus) => {
  return {
    picking: 'Поиск исполнителя',
    processing: 'Ожидание перевода',
    verifying: 'Проверка перевода',
    completed: 'Завершён',
    cancelled: 'Отменён',
    disputing: 'Спор',
  }[status]
}

export const toHumanTraderDepositCancellationCode = (code: TraderDepositCancellationCode | null) => {
  if (!code) {
    return 'Сделка не отменена'
  }
  return {
    picking_trader_manual: 'Трейдер отказался от сделки на этапе подбора исполнителя',
    processing_trader_manual: 'Трейдер отказался от сделки на этапе перевода денег',
    disputing_admin_disputeCompletion: 'Спор завершён администратором в пользу платформы',
  }[code]
}

export const toHumanTraderDepositCancellationReason = (
  code: TraderDepositCancellationCode | null,
  message: string | null
) => {
  const humanCode = toHumanTraderDepositCancellationCode(code)
  if (!message) {
    return humanCode
  }
  return `${humanCode}: ${message}`
}
